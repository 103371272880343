<template>
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4142 0L23.1213 6.70711L16.4142 13.4142L15 12L19.292 7.707L0 7.70711V5.70711L19.292 5.707L15 1.41421L16.4142 0Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
    }
  }
}
</script>
