<template>
  <div class="category">
    <div class="category__top">
      <div class="category__header">
        <h1 class="category__header__title">{{ categoryName }}</h1>
        <div class="category__header__count" v-if="categoryResultsCount">
          {{ categoryResultsCount }}
        </div>
      </div>
      <SearchInput type="category" :category="categoryName" />
    </div>
    <ResultsSection v-if="$store.state.search.indexes" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SearchInput from '@/components/SearchInput.vue'
import ResultsSection from '@/components/ResultsSection.vue'

export default {
  components: {
    SearchInput,
    ResultsSection,
  },
  computed: {
    ...mapGetters(['categoryResultsCount']),
    categoryName() {
      return this.$store.state.search.indexes?.find(index => index.key === this.$route.params.category)?.label ?? ''
    },
  },
  watch: {
    '$route.params.category': {
      handler() {
        if(this.$route.params.category) {
          this.$store.commit('setCurrentCategory', this.$route.params.category)
        }
      },
      immediate: true,
    },
  }
}
</script>

<style lang="scss">
.category {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - $navbar-height);

  &__top {
    padding: 20px 16px;

    @include mq($from: mobile, $until: tablet) {
      padding: 40px 30px;
    }

    @include mq($from: tablet) {
      padding: 80px 65px 50px;
    }
  }

  &__header {
    display: flex;
    margin-bottom: 10px;

    @include mq($from: tablet) {
      margin-bottom: 24px;
    }

    &__title {
      @include mq($from: mobile, $until: tablet) {
        font-size: 40px;
      }
    }

    &__count {
      background-color: $color-black-primary;
      border-radius: 47px;
      color: white;
      font-size: 11px;
      font-weight: $font-weight-medium;
      line-height: 1;
      height: min-content;
      margin-left: 8px;
      padding: 3px 7px;

      @include mq($from: mobile) {
        font-size: 15px;
        padding: 4px 10px;
      }
    }
  }
}
</style>
