<template>
  <div class="cart">
    <div class="cart__trigger" v-if="isTablet" @click="toggleDropdown">
      <IconCart v-if="!showCart" />
      <div v-if="!showCart && cartCount" class="cart__trigger__chip">
        {{ cartCount }}
      </div>
      <IconCross v-if="showCart" />
    </div>
    <div class="cart__trigger" v-else>
      <IconCart @click="toggleDropdown" />
      <div v-if="cartCount" class="cart__trigger__chip">
        {{ cartCount }}
      </div>
    </div>
    <Transition name="slide-right" @enter="onEnter" @after-leave="onAfterLeave">
      <div class="cart__dropdown" v-show="showCart">
        <template v-if="cart.length">
          <div class="cart__header">
            <p class="cart__header__text">
              {{ $t('cart-header') }}
            </p>
            <img
              src="@/assets/images/cross.svg"
              v-if="!isTablet"
              @click="closeDropdown"
              class="cart__header__icon"
            />
          </div>
          <div class="cart__products">
            <div
              class="cart__product"
              v-for="item in cart"
              :key="item.objectID"
            >
              <img class="cart__product__image" :src="itemThumbnail(item)" />
              <div class="cart__product__info">
                <p class="cart__product__info__name">{{ item.title }}</p>
                <div
                  class="cart__product__type"
                  v-for="variant in item.variants"
                  :key="variant.id"
                >
                  <p class="cart__product__type__format">
                    {{ variant.extension }}
                  </p>
                  <p class="cart__product__type__size">{{ variant.label }}</p>
                  <IconBin
                    @click="removeVariantFromCart(item.objectID, variant.id)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="cart__actions">
            <div class="cart__actions__data">
              <p class="cart__actions__data__count">
                {{ cartCount }} {{ $tc('files', cartCount) }}
              </p>
              <p class="cart__actions__data__size">
                {{ (cartSize / 1048576).toFixed(2) }} MB
              </p>
            </div>
            <div class="cart__actions__buttons">
              <Button outline :label="$t('button.clear')" @click="showClearCartDialog = true">
                <IconBin />
              </Button>
              <Button @click="downloadCart">
                <Loader v-if="isDownloading" />
                <template v-else>
                  <IconDownload />
                  <span>{{ $t('button.download-all') }}</span>
                </template>
              </Button>
            </div>
          </div>
        </template>
        <div class="cart__empty" v-else>
          <div class="cart__empty__inner">
            <img src="@/assets/images/cart-empty.svg" />
            <p class="cart__empty__inner__text">
              {{ $t('cart-empty') }}
            </p>
            <a @click="backToLanding" class="cart__empty__inner__link">
              {{ $t('link.to-start') }}
            </a>
          </div>
        </div>
        <div v-if="showClearCartDialog" class="cart__clear-dialog">
          <div class="cart__clear-dialog">
            <div class="cart__clear-dialog__header">
              <p class="cart__clear-dialog__header__text">
                {{ $t('clear-cart-modal') }}
              </p>
              <p class="cart__clear-dialog__header__count">
                {{ cartCount }} {{ $tc('files', cartCount) }}
              </p>
            </div>
            <div class="cart__clear-dialog__buttons">
              <Button outline :label="$t('button.cancel')" @click="closeDialog">
                <IconCross />
              </Button>
              <Button :label="$t('button.clear')" @click="emptyCart">
                <IconBin />
              </Button>
            </div>
          </div>
        </div>
        <div
          class="cart__clear-overlay"
          v-show="showClearCartDialog"
          @click="closeDialog"
        />
      </div>
    </Transition>
    <div class="cart__overlay" v-show="showOverlay" @click="closeDropdown" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElNotification } from 'element-plus'
import Loader from '@/components/Loader'
import Button from '@/components/Button.vue'

import IconBin from '@/components/icons/IconBin'
import IconCart from '@/components/icons/IconCart'
import IconCross from '@/components/icons/IconCross'
import IconDownload from '@/components/icons/IconDownload'

export default {
  components: {
    Loader,
    Button,
    IconBin,
    IconCart,
    IconCross,
    IconDownload,
  },
  data() {
    return {
      showOverlay: false,
      showClearCartDialog: false,
    }
  },
  mounted() {
    if (this.showCart) this.showOverlay = true
  },
  computed: {
    ...mapGetters([
      'isTablet',
      'isDownloading',
      'showCart',
      'cart',
      'cartCount',
      'user',
      'defaultThumbnails',
    ]),
    cartSize() {
      const variants = this.cart.map((item) => item.variants).flat()
      const bytes = variants.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.size
      }, 0)

      return bytes
    },
  },
  methods: {
    itemThumbnail(item) {
      if(!this.defaultThumbnails)
        return

      if (item.thumbnail.path) {
        return this.defaultThumbnails.assets_uri + item.thumbnail.path
      } else {
        return (
          this.defaultThumbnails.assets_uri +
          this.defaultThumbnails.default_thumbnails[item.thumbnail.ext]
        )
      }
    },
    onEnter() {
      this.showOverlay = true
    },
    onAfterLeave() {
      this.showOverlay = false
    },
    toggleDropdown() {
      this.$store.commit('setShowCart', !this.showCart)

      if (this.showCart) this.$store.commit('clearOtherDropdowns', 'showCart')

      if (this.showClearCartDialog) this.closeDialog()
    },
    closeDropdown() {
      this.$store.commit('setShowCart', false)
      this.closeDialog()
    },
    backToLanding() {
      this.$router.push({ path: '/' })
      this.$store.commit('setShowCart', false)
    },
    removeVariantFromCart(objectId, variantId) {
      this.$store.commit('removeVariantFromCart', { objectId, variantId })
    },
    closeDialog() {
      this.showClearCartDialog = false
    },
    emptyCart() {
      this.$store.commit('clearCart')
      this.closeDialog()
    },
    downloadCart() {
      // -1 means no limit
      const aboveDownloadLimit = process.env.$VUE_APP_HAS_AUTH
        ? this.user.download_limit > -1 &&
          this.cartSize > this.user.download_limit
        : false

      if (aboveDownloadLimit) {
        ElNotification({
          title: this.$t('notification.download-limit', {
            mb: (this.user.download_limit / 1048576).toFixed(0),
          }),
          position: 'bottom-right',
          duration: 2000,
          type: 'error',
          showClose: false,
        })

        return
      }

      const variantIds = this.cart.reduce((previousValue, currentValue) => {
        const variantIds = currentValue.variants.map((variant) => variant.id)
        return previousValue.concat(variantIds)
      }, [])

      this.$store.dispatch('downloadAssets', { variantIds })
      ElNotification({
        title: this.$t('notification.download-started'),
        position: 'bottom-left',
        duration: 4000,
        type: 'success',
        showClose: false,
      })
    },
  },
}
</script>

<style lang="scss">
.cart {
  @include flex-center-align;
  padding: 0 15px;

  &__trigger {
    @include flex-center-align;
    cursor: pointer;
    position: relative;

    svg {
      cursor: pointer;
      fill: $color-nav-icons;
    }

    &__chip {
      background-color: $color-primary;
      color: white;
      font-size: 10px;
      font-weight: $font-weight-bold;
      line-height: 1;
      padding: 4px;
      position: absolute;
      top: 10px;
      left: 18px;
    }
  }

  &__dropdown {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $navbar-height;
    right: 0;
    height: calc(100vh - $navbar-height);
    width: 100vw;
    z-index: $zIndex-navbar-dropdown;

    @include mq($from: mobile) {
      width: 470px;
    }
  }

  &__header {
    @include flex-center-align;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-300;
    padding: 16px;

    @include mq($from: mobile) {
      padding: 28px 40px;
    }

    &__text {
      font-size: 22px;
      font-weight: $font-weight-medium;
      line-height: 38px;
      text-transform: capitalize;

      .elf & {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }
    }

    &__icon {
      cursor: pointer;
    }
  }

  &__products {
    flex-grow: 1;
    padding: 16px;
    overflow-y: scroll;

    @include mq($from: mobile) {
      padding: 40px;
    }
  }

  &__product {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;

    &__image {
      width: 80px;
    }

    &__info {
      width: 100%;

      &__name {
        font-weight: $font-weight-medium;
        margin-bottom: 10px;
        word-break: break-word;
      }
    }

    &__type {
      display: flex;
      border-bottom: 1px solid $color-black-10;
      font-size: 14px;
      padding: 6px 0;

      &__format {
        font-stretch: condensed;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
      }

      &__size {
        flex: 100%;
        margin-left: 8px;
      }

      svg {
        cursor: pointer;
        fill: $color-grey-600;

        &:hover {
          fill: $color-primary;
        }
      }
    }
  }

  &__actions {
    border-top: 1px solid $color-grey-300;
    padding: 20px 16px;

    @include mq($from: mobile) {
      padding: 20px 40px 40px;
    }

    &__data {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      &__count {
        font-weight: $font-weight-medium;
      }

      &__size {
        color: $color-grey-600;
      }
    }

    &__buttons {
      display: flex;
      gap: 6px;

      .button--outline {
        width: 115px;
      }

      .button--solid {
        width: 100%;
      }
    }
  }

  &__empty {
    flex-grow: 1;
    padding: 50px;

    &__inner {
      @include flex-center;
      flex-direction: column;
      background-color: $color-grey-200;
      height: 100%;

      &__text {
        color: $color-grey-700;
        margin: 28px 0 70px;
      }

      &__link {
        border-bottom: 2px solid $color-primary;
        cursor: pointer;
        line-height: 32px;
        font-weight: $font-weight-medium;
      }
    }
  }

  &__clear-dialog {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: white;
    padding: 24px;
    max-width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $zIndex-clear-dialog;

    &__header {
      display: flex;
      gap: 8px;

      &__text {
        font-size: 18px;
        font-weight: $font-weight-medium;
        max-width: 350px;
        word-break: break-word;
      }

      &__count {
        color: $color-error;
        white-space: nowrap;
      }
    }

    &__buttons {
      display: flex;
      gap: 10px;
    }
  }

  &__clear-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-black-20;
  }

  &__overlay {
    position: absolute;
    top: $navbar-height;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: $color-black-20;
  }
}

.slide-right-enter-active {
  transition: right 0.5s ease;
}

.slide-right-leave-active {
  transition: right 0.2s ease;
}

.slide-right-enter-from,
.slide-right-leave-to {
  right: -100vw;

  @include mq($from: mobile) {
    right: -470px;
  }
}
</style>
