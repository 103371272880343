<template>
  <div class="user">
    <IconUser
      class="user__trigger"
      @click="toggleDropdown"
    />
    <div class="user__dropdown" v-show="showAccountDropdown">
      <div class="user__dropdown__header">
        <p>{{ $t('hello') }}, {{ user.first_name }}</p>
      </div>
      <div class="user__dropdown__list">
        <a class="user__dropdown__list__item" @click="toAccountPage">
          {{ $t('link.account') }}
        </a>
      </div>
      <div class="user__dropdown__bottom">
        <Button :label="$t('button.logout')" @click="logout" />
      </div>
    </div>
    <div class="user__overlay" v-show="showAccountDropdown" @click="closeDropdown" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/Button.vue'
import IconUser from '@/components/icons/IconUser'

export default {
  components: {
    Button,
    IconUser,
  },
  computed: {
    ...mapGetters(['user', 'showAccountDropdown']),
  },
  methods: {
    toggleDropdown() {
      this.$store.commit('setShowAccountDropdown', !this.showAccountDropdown)

      if (this.showAccountDropdown) this.$store.commit('clearOtherDropdowns', 'showAccountDropdown')
    },
    closeDropdown() {
      this.$store.commit('setShowAccountDropdown', false)
    },
    toAccountPage() {
      this.$router.push({ name: 'account' })
      this.closeDropdown()
    },
    logout() {
      this.$store.dispatch('userLogout')
    },
  },
}
</script>

<style lang="scss" scoped>
.user {
  @include flex-center-align;
  padding: 0 15px;

  &__trigger {
    fill: $color-nav-icons;
    cursor: pointer;
  }

  &__dropdown {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $navbar-height;
    right: 0;
    z-index: $zIndex-navbar-dropdown;

    &__header,
    &__list {
      padding: 30px;
    }

    &__header {
      border-bottom: 1px solid $color-primary;
    }

    &__list {
      &__item {
        cursor: pointer;
        font-weight: $font-weight-medium;
      }
    }

    &__bottom {
      @include flex-center;
      border-top: 1px solid $color-primary;
      padding: 20px 30px;
    }
  }

  &__overlay {
    position: absolute;
    top: $navbar-height;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: $color-black-20;
  }
}
</style>
