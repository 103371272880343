<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 0H6.5V2H11.5V0ZM0.5 3H1.5H16.5H17.5V5H16.25L14.5 19H3.5L1.75 5H0.5V3ZM3.76556 5H14.2344L12.7344 17H5.26556L3.76556 5Z"
    />
  </svg>
</template>
