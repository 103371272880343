<template>
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 0V3H17V21H0V3H4V0H13ZM15 5H13V7H11V5H6V7H4V5H2V19H15V5ZM11 2H6V3H11V2Z"
    />
  </svg>
</template>
