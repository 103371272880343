const path = require('path')

module.exports = {
  publicPath:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_PUBLIC_PATH
      : '/',
  configureWebpack: {
    resolve: {
      extensions: ['.js', '.vue', '.json', '.scss'],
      alias: {
        styles: path.resolve(__dirname, 'src/assets/styles'),
        helpers: path.resolve(__dirname, 'src/helpers'),
      },
    },
  },
  pages: {
    index: {
      entry: 'src/main.js',
      title: process.env.VUE_APP_TAB_TITLE,
    },
  },
  css: {
    // Enable CSS source maps.
    // sourceMap: true,
    loaderOptions: {
      scss: {
        additionalData: `
          $brand: ${process.env.VUE_APP_BRAND_SLUG};
          @import "~@/assets/styles/_variables.scss";
          @import "~@/assets/styles/_variables-${process.env.VUE_APP_BRAND_SLUG}.scss";
          @import "~@/assets/styles/_fonts-${process.env.VUE_APP_BRAND_SLUG}.scss";
          @import "~@/assets/styles/_mixins.scss";
          @import "sass-mq";
        `,
      },
    },
  },
}
