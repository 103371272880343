import * as algoliaSearch from 'helpers/algolia'
import store from '../store'

export function initSearchClient(searchClient) {
  if (searchClient.name === 'algolia') {
    algoliaSearch.initSearchClient(searchClient)
  }
}

export function getFacetValues() {
  if (store.getters.searchClient.name === 'algolia') {
    algoliaSearch.getFacetValues()
  }
}

export function searchAllIndexes(hitsPerPage) {
  if (store.getters.searchClient.name === 'algolia') {
    algoliaSearch.searchAllIndexes(hitsPerPage)
  }
}

export function searchIndex(index, hitsPerPage, filters) {
  if (store.getters.searchClient?.name === 'algolia') {
    algoliaSearch.searchIndex(index, hitsPerPage, filters)
  }
}
