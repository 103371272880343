import algoliasearch from 'algoliasearch/lite'
import store from '../store'
import { createNullCache } from '@algolia/cache-common'
// https://www.algolia.com/doc/api-client/getting-started/customize/javascript/?client=javascript#caching-requests-and-responses

let client
let indexKeys = {}

export function initSearchClient(searchClient) {
  client = algoliasearch(
    searchClient.application_id,
    searchClient.search_api_key,
    {
      responsesCache: createNullCache(),
      requestsCache: createNullCache(),
    },
  )

  store.getters.indexes.forEach((index) => {
    indexKeys[index.key] = client.initIndex(index.key)
  })
}

export function getFacetValues() {
  store.getters.indexes.forEach((index) => {
    index.filters.forEach((filter) => {
      indexKeys[index.key]
        ?.searchForFacetValues(filter.key)
        .then(({ facetHits }) => {
          store.commit('setFilters', {
            index: index.key,
            filter: filter.key,
            facetHits,
          })
        })
    })
  })
}

export function searchAllIndexes(hitsPerPage = 20) {
  const queries = store.getters.indexes.map((index) => ({
    indexName: index.key,
    query: store.getters.generalSearchText,
    params: {
      hitsPerPage,
    },
  }))

  client?.multipleQueries(queries).then(({ results }) => {
    store.commit('setGeneralResults', results)
  })
}

export function searchIndex(index, hitsPerPage = 20, filters = null) {
  let filtersArray = []

  filters?.forEach((filter, index) => {
    filter.values.forEach((value) => {
      if (filtersArray[index]) {
        filtersArray[index].push(`"${filter.name}":"${value}"`)
      } else {
        ;(filtersArray[index] = []).push(`"${filter.name}":"${value}"`)
      }
    })
  })

  filtersArray.forEach((array, index) => {
    filtersArray[index] = '(' + array.join(' OR ') + ')'
  })

  const multipleFilters = filtersArray?.join(' AND ')

  indexKeys[index]
    ?.search(store.getters.categorySearchText, {
      hitsPerPage,
      filters: multipleFilters,
    })
    .then((response) => {
      store.commit('setCategoryResults', response)
    })
}
