<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.375 0.5C18.9334 0.5 24.25 5.81662 24.25 12.375C24.25 14.9735 23.4154 17.3771 21.9995 19.3323L25.7697 23.1019L24.0019 24.8697L20.3271 21.1944C18.222 23.0937 15.4335 24.25 12.375 24.25C5.81662 24.25 0.5 18.9334 0.5 12.375C0.5 5.81662 5.81662 0.5 12.375 0.5ZM12.375 3C7.19733 3 3 7.19733 3 12.375C3 17.5527 7.19733 21.75 12.375 21.75C17.5527 21.75 21.75 17.5527 21.75 12.375C21.75 7.19733 17.5527 3 12.375 3Z"
    />
  </svg>
</template>