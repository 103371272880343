<template>
  <div class="card" @click.stop="showDialog">
    <el-checkbox class="checkbox-card" v-model="checked" @click.stop />
    <img class="card__image" :class="{ 'card__image--default': !data.thumbnail.path }" :src="itemThumbnail" />
    <div v-if="!isTablet" class="card__slider">
      <p class="card__slider__title">{{ data.title }}</p>
      <ul>
        <li v-for="variant in sortedVariants" :key="variant.id">
          <p class="card__slider__format">{{ variant.extension }}</p>
          <p class="card__slider__size">
            {{ (variant.extension === 'pdf' && !isNaN(+variant.label)
              ? $t('pages') + ': '
              : '')
              + variant.label
            }}
          </p>
          <IconCart @click="addVariantToCart(variant.id)" />
          <Loader v-if="variantDownloading === variant.id && isDownloading" gray small />
          <IconDownload v-else @click="downloadVariant(variant.id)" />
        </li>
      </ul>
      <div class="card__slider__buttons">
        <Button @click="downloadItem">
          <Loader v-if="isItemDownloading && isDownloading" />
          <template v-else>
            <IconDownload />
            <span>
              {{
                data.variants.length > 1
                ? $t('button.download-all')
                : $t('button.download')
              }}
            </span>
          </template>
        </Button>
        <Button :label="data.variants.length > 1 ? $t('button.add-all') : $t('button.add')" @mousedown="addItemToCart(data)">
          <IconCart />
        </Button>
      </div>
    </div>
    <div v-if="isTablet" class="card__text">
      <p class="card__text__title">{{ data.title }}</p>
      <div class="card__text__formats">
        <p class="card__text__formats__variant" v-for="variant in data.variants" :key="variant.id">
          {{ variant.extension }}
        </p>
      </div>
    </div>
    <el-dialog v-if="isTablet" v-model="openDialog" custom-class="card__dialog" :show-close="false">
      <div class="card__dialog__header">
        <p>{{ data.title }}</p>
        <img class="card__dialog__header__image" @click="closeDialog" src="@/assets/images/cross.svg" />
      </div>
      <div class="card__dialog__body">
        <img class="card__dialog__body__image" :class="{ 'card__dialog__body__image--default': !data.thumbnail.path }"
          :src="itemThumbnail" />
        <ul>
          <li v-for="variant in sortedVariants" :key="variant.id">
            <p class="card__dialog__body__format">{{ variant.extension }}</p>
            <p class="card__dialog__body__size">
              {{ (variant.extension === 'pdf' && !isNaN(+variant.label)
                ? $t('pages') + ': '
                : '')
                + variant.label
              }}
            </p>
            <IconCart @click="addVariantToCart(variant.id)" />
            <Loader v-if="variantDownloading === variant.id && isDownloading" gray small />
            <IconDownload v-else @click="downloadVariant(variant.id)" />
          </li>
        </ul>
        <div class="card__dialog__body__buttons">
          <Button @click="downloadItem">
            <Loader v-if="isItemDownloading && isDownloading" />
            <template v-else>
              <IconDownload />
              <span>
                {{
                  data.variants.length > 1
                  ? $t('button.download-all')
                  : $t('button.download')
                }}
              </span>
            </template>
          </Button>
          <Button :label="data.variants.length > 1 ? $t('button.add-all') : $t('button.add')" @click="addItemToCart(data)">
            <IconCart />
          </Button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElNotification } from 'element-plus'

import Loader from '@/components/Loader'
import Button from '@/components/Button.vue'
import IconCart from '@/components/icons/IconCart'
import IconDownload from '@/components/icons/IconDownload'

export default {
  components: {
    Loader,
    Button,
    IconCart,
    IconDownload,
  },
  emits: ['selected', 'deselected'],
  props: {
    data: {
      type: Object,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
      openDialog: false,
      variantDownloading: null,
      isItemDownloading: false,
    }
  },
  computed: {
    ...mapGetters(['isTablet', 'isDownloading', 'cartCount', 'defaultThumbnails']),
    itemThumbnail() {
      if (this.data.thumbnail.path) {
        return this.defaultThumbnails.assets_uri + this.data.thumbnail.path
      } else {
        return this.defaultThumbnails.assets_uri + this.defaultThumbnails.default_thumbnails[this.data.thumbnail.ext]
      }
    },
    itemSize() {
      return this.data.variants.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.size
      }, 0)
    },
    sortedVariants() {
      return [...this.data.variants].sort((a, b) => {
        if (a.extension < b.extension) return -1
        if (a.extension > b.extension) return 1

        return 0;
      })
    },
  },
  watch: {
    checked() {
      if (this.checked) {
        this.$emit('selected', {
          objectID: this.data.objectID,
          itemSize: this.itemSize
        })
      } else {
        this.$emit('deselected', {
          objectID: this.data.objectID,
          itemSize: this.itemSize
        })
      }
    },
    isSelected() {
      if (!this.isSelected) this.checked = this.isSelected
    },
    isDownloading() {
      if (!this.isDownloading) {
        this.variantDownloading = null
        this.isItemDownloading = false
      }
    }
  },
  methods: {
    showDialog() {
      if (this.isTablet) this.openDialog = true
    },
    closeDialog() {
      this.openDialog = false
    },
    addVariantToCart(variantId) {
      if (this.cartCount === 100) {
        ElNotification({
          title: this.$t('notification.cart-limit', {
            number: 100
          }),
          position: 'bottom-right',
          duration: 2000000,
          type: 'error',
          showClose: false,
        })

        return
      }

      const variantOnly = {
        ...this.data,
        variants: [
          this.data.variants.find((variant) => variant.id === variantId),
        ],
      }
      this.$store.commit('setNewlyAddedItems', [variantOnly])
      this.$store.commit('addVariantToCart', variantOnly)
      this.closeDialog()
    },
    downloadVariant(variantId) {
      this.variantDownloading = variantId
      this.$store.dispatch('downloadAssets', { title: this.data.title, variantIds: [variantId] })
    },
    addItemToCart() {
      if (this.cartCount + this.data.variants.length > 100) {
        ElNotification({
          title: this.$t('notification.cart-limit', {
            number: 100
          }),
          position: 'bottom-right',
          duration: 2000000,
          type: 'error',
          showClose: false,
        })

        return
      }

      this.$store.commit('setNewlyAddedItems', [this.data])
      this.$store.commit('addItemToCart', this.data)
      this.closeDialog()
    },
    downloadItem() {
      this.isItemDownloading = true
      const variantIds = this.data.variants.map((variant) => variant.id)
      this.$store.dispatch('downloadAssets', { title: this.data.title, variantIds })
    },
  },
}
</script>

<style lang="scss">
.card {
  @include flex-center-align;
  flex-direction: column;
  background-color: white;
  cursor: pointer;
  padding: 6px;
  height: 220px;
  width: 100%;
  overflow: hidden;
  position: relative;

  @include mq($from: tablet) {
    cursor: default;
    height: 420px;
    max-width: 320px;
    padding: 14px;
  }

  .checkbox-card {
    align-self: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }

  &__image {
    max-height: calc(100% - 77px);
    max-width: 100%;
    margin-top: auto;
    margin-bottom: auto;

    @include mq($until: tablet) {
      margin: auto 0;
    }

    &--default {
      max-width: 30%;
    }
  }

  &:hover {
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.25);

    .card__slider {
      box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.07);
      max-height: 90%; // space for checkbox
      transition: max-height 0.2s ease-in;
    }
  }

  &__slider {
    @include flex-center-align;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    background-color: white;
    max-height: 70px;
    width: 100%;
    transition: max-height 0.2s ease-out;

    &__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-shrink: 0;
      max-width: 100%;
      padding: 20px;
    }

    ul {
      margin-bottom: 24px;
      padding: 0 20px;
      width: 100%;
      overflow-y: auto;

      li {
        @include flex-center-align;
        font-size: 14px;
        list-style: none;
        padding: 8px 0;

        &:not(:last-of-type) {
          border-bottom: 1px solid $color-black-10;
        }

        .card__slider__format {
          font-stretch: condensed;
          font-weight: $font-weight-medium;
          text-transform: uppercase;
        }

        .card__slider__size {
          flex: 100%;
          margin-left: 8px;
        }

        svg {
          cursor: pointer;
          fill: $color-grey-600;
          margin-left: 16px;

          &:hover {
            fill: $color-primary;
          }
        }

        .loader {
          margin: 0 4px 0 16px;
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 100%;
      padding: 0 20px 20px;
    }
  }

  &__text {
    font-size: 12px;
    margin-top: 8px;
    overflow: hidden;
    width: 100%;

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }

    &__formats {
      display: flex;
      justify-content: space-evenly;
      color: $color-grey-600;
      font-size: 11px;
      font-stretch: condensed;
      font-weight: $font-weight-bold;
      margin: 4px 0 7px;

      &__variant {
        text-transform: uppercase;
      }
    }
  }

  &__dialog {
    cursor: default;

    &__header {
      @include flex-center-align;
      justify-content: space-between;
      border-bottom: 1px solid $color-black-10;
      padding: 20px;

      &__image {
        cursor: pointer;
      }
    }

    &__body {
      @include flex-center-align;
      flex-direction: column;
      padding: 20px;

      &__image {
        margin: 0 auto;
        max-height: 40vh;
        max-width: 100%;
      }

      ul {
        margin-top: 18px;
        margin-bottom: 14px;
        max-height: 130px;
        width: 100%;
        overflow-y: auto;

        li {
          @include flex-center-align;
          font-size: 14px;
          list-style: none;
          padding: 8px 0;

          &:not(:last-of-type) {
            border-bottom: 1px solid $color-black-10;
          }

          .card__dialog__body__format {
            font-stretch: condensed;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            white-space: nowrap;
          }

          .card__dialog__body__size {
            flex: 100%;
            margin-left: 8px;
          }

          svg {
            cursor: pointer;
            fill: $color-grey-600;
            margin-left: 16px;

            &:hover {
              fill: $color-primary;
            }
          }
        }
      }

      &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;

        button {
          @include mq($until: mobile) {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
