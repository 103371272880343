<template>
  <div class="language" :class="{'language__boxed': showLanguageDropdown}">
    <div class="language__trigger" @click="toggleDropdown">
      <p class="language__trigger__text">
        {{ currentLanguage }}
      </p>
      <img :src="showCurrentLanguageFlag" />
    </div>
    <div class="language__dropup" v-show="showLanguageDropdown">
      <ul>
        <li v-if="currentLanguage !== 'en'" @click="switchLanguage('en')">
          EN<img src="@/assets/images/flag-uk.svg" />
        </li>
        <li v-if="currentLanguage !== 'de'" @click="switchLanguage('de')">
          DE<img src="@/assets/images/flag-austria.svg" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentLanguage', 'showLanguageDropdown']),
    showCurrentLanguageFlag() {
      switch (this.currentLanguage) {
        case 'en':
          return require('@/assets/images/flag-uk.svg')
        case 'de':
          return require('@/assets/images/flag-austria.svg')
        default:
          return require('@/assets/images/flag-austria.svg')
      }
    },
  },
  methods: {
    toggleDropdown() {
      this.$store.commit('setShowLanguageDropdown', !this.showLanguageDropdown)
    },
    closeDropdown() {
      this.$store.commit('setShowLanguageDropdown', false)
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('setCurrentLanguage', lang)
      this.closeDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>
.language {
  @include flex-center-align;
  margin-bottom: -8px;
  flex-direction: column-reverse;
  gap: 24px;
  padding: 14px 12px;

  &__boxed {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  }

  &__trigger {
    @include flex-center-align;
    gap: 8px;
    cursor: pointer;

    &__text {
      font-size: 14px;
      font-weight: $font-weight-medium;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  &__dropup {
    ul li {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      list-style: none;
      font-size: 14px;
      font-weight: $font-weight-medium;
      cursor: pointer;

      &:hover {
        color: $color-primary;
      }
    }
  }
}
</style>
