<template>
  <div class="search">
    <div class="search__landing" v-if="type === 'landing'" @click="focusInput">
      <IconSearch class="prefix-icon" />
      <input
        ref="inputRef"
        v-model="localGeneralSearchText"
        @keyup.enter="landingSearch"
      />
      <div class="search__landing__underline">
        <p>{{ localGeneralSearchText }}</p>
      </div>
      <p v-show="!localGeneralSearchText" class="search__landing__placeholder">
        {{ isTablet ? '' : $t('search-suggestion') }}
      </p>
      <IconArrowRight
        v-show="localGeneralSearchText"
        color="#262626"
        @click="landingSearch"
        class="suffix-icon"
      />
    </div>
    <div class="search__navigation" v-if="type === 'nav'">
      <el-input
        v-model="localGeneralSearchText"
        :placeholder="$t('placeholder-search')"
        @keyup.enter="closeDropdown"
      >
        <template #prefix>
          <IconSearch />
        </template>
      </el-input>
    </div>
    <div class="search__mobile" v-if="type === 'mobile'" @click="focusInput">
      <input
        ref="inputRef"
        v-model="localGeneralSearchText"
        :placeholder="$t('placeholder-search')"
      />
      <div class="search__mobile__underline">
        <p>{{ localGeneralSearchText }}</p>
      </div>
      <IconSearch v-if="!localGeneralSearchText" />
      <img
        v-else
        @click="clearSearchText"
        src="@/assets/images/cross-box.svg"
        class="suffix-icon"
      />
    </div>
    <div
      class="search__category"
      v-if="type === 'category'"
      @click="focusInput"
    >
      <IconSearch class="prefix-icon" />
      <div class="search__category__input">
        <input
          ref="inputRef"
          v-model="localCategorySearchText"
          :placeholder="`${$t('placeholder-search-category', {
            category: capitalizedCategory,
          })}`"
        />
        <div class="search__category__underline">
          <p>{{ localCategorySearchText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { searchAllIndexes, searchIndex } from '@/helpers/search'
import IconSearch from '@/components/icons/IconSearch'
import IconArrowRight from '@/components/icons/IconArrowRight'

export default {
  components: {
    IconSearch,
    IconArrowRight,
  },
  props: {
    type: {
      type: String,
      default: 'landing',
    },
    category: {
      type: String,
    },
  },
  data() {
    return {
      localGeneralSearchText: '',
      localCategorySearchText: '',
    }
  },
  mounted() {
    this.focusInput()

    this.localGeneralSearchText = this.generalSearchText
    this.localCategorySearchText = this.categorySearchText
  },
  computed: {
    ...mapGetters([
      'isTablet',
      'generalSearchText',
      'categorySearchText',
      'currentCategory',
      'showAccountDropdown',
      'showLanguageDropdown',
    ]),
    capitalizedCategory() {
      try {
        return this.category[0].toUpperCase() + this.category.slice(1).toLowerCase()
      } catch (error) {
        return this.category ?? ''
      }
    },
  },
  watch: {
    generalSearchText() {
      this.localGeneralSearchText = this.generalSearchText
    },
    categorySearchText() {
      this.localCategorySearchText = this.categorySearchText
    },
    localGeneralSearchText() {
      this.$store.commit('setGeneralSearchText', this.localGeneralSearchText)

      if (this.currentCategory === 'alle') {
        searchAllIndexes()
      } else {
        searchAllIndexes()
        searchIndex(this.currentCategory)
      }
    },
    localCategorySearchText() {
      this.$store.commit('setCategorySearchText', this.localCategorySearchText)

      if (this.currentCategory === 'alle') {
        searchAllIndexes()
      } else {
        searchAllIndexes()
        searchIndex(this.currentCategory)
      }
    },
  },
  methods: {
    focusInput() {
      this.$refs.inputRef?.focus()
    },
    clearSearchText() {
      this.localGeneralSearchText = ''
    },
    closeDropdown() {
      this.$store.commit('setShowMenuDropdown', false)
    },
    landingSearch() {
      searchAllIndexes()

      this.$store.commit('setCurrentCategory', 'alle')
      this.$router.push({ name: 'results' })
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  &__landing {
    @include flex-center-align;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    position: relative;

    input {
      border: none;
      outline: none;
      font-stretch: condensed;
      font-weight: $font-weight-medium;
      font-size: 30px;
      line-height: 1;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding: 14px 0;
      width: 100%;

      @include mq($from: tablet) {
        font-size: 40px;
        padding: 24px 0;
      }
    }

    .prefix-icon {
      margin: 0 18px;

      @include mq($from: tablet) {
        margin: 0 30px;
      }
    }

    .suffix-icon {
      cursor: pointer;
      margin: 0 18px 0 auto;

      @include mq($from: tablet) {
        margin: 0 30px 0 auto;
      }

      &:hover {
        transform: translateX(4px);
        transition: all 0.2s ease;
      }
    }

    &__underline {
      border-bottom: 3px solid $color-primary;
      font-stretch: condensed;
      font-weight: $font-weight-medium;
      font-size: 30px;
      line-height: 1;
      letter-spacing: 2px;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;
      left: 56px;
      max-width: calc(100% - 95px);

      @include mq($from: tablet) {
        font-size: 40px;
        left: 80px;
        max-width: calc(100% - 130px);
      }

      p {
        visibility: hidden;
      }
    }

    &__placeholder {
      color: $color-grey-500;
      font-size: 16px;
      text-transform: none;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__navigation {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 500px;
  }

  &__mobile {
    @include flex-center-align;
    justify-content: space-between;
    border-bottom: 1px solid $color-black-10;
    padding: 0 16px;
    position: relative;

    input {
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      padding: 15px 0;
      width: 100%;

      &::placeholder {
        color: $color-grey-700;
        font-weight: $font-weight-regular;
        text-transform: none;
      }
    }

    &__underline {
      border-bottom: 3px solid $color-primary;
      font-weight: $font-weight-bold;
      font-size: 16px;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;
      left: 16px;

      p {
        visibility: hidden;
      }
    }

    .suffix-icon {
      cursor: pointer;
    }
  }

  &__category {
    display: flex;
    gap: 15px;

    .prefix-icon {
      margin-top: 14px;
    }

    &__input {
      border-bottom: 1px solid $color-black-20;
      position: relative;
      width: 100%;

      input {
        border: none;
        outline: none;
        font-stretch: condensed;
        font-size: 18px;
        font-weight: $font-weight-medium;
        line-height: 1;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 14px 0;
        width: 100%;

        &::placeholder {
          color: $color-grey-500;
          font-family: 'DINNextLTPro', Helvetica, Arial, sans-serif;
          font-size: 16px;
          font-weight: $font-weight-regular;
          letter-spacing: 1px;
          text-transform: none;
        }
      }
    }

    &__underline {
      border-bottom: 3px solid $color-primary;
      font-stretch: condensed;
      font-weight: $font-weight-medium;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 2px;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;
      left: 0;

      p {
        visibility: hidden;
      }
    }
  }
}
</style>
