<template>
  <div class="loader" :class="{ 'loader--gray': gray, 'loader--small': small }">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    gray: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss">
.loader {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 4px solid white;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: white transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &--gray {
    div {
      border-color: #808080 transparent transparent transparent;
    }
  }

  &--small {
    height: 13px;
    width: 13px;

    div {
      border-color: #808080 transparent transparent transparent;
      border-width: 2px;
      height: 13px;
      width: 13px;
    }
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
