import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './user'
import search from './search'
import cart from './cart'
import general from './general'

const store = createStore({
  plugins: [createPersistedState({paths: ['user', 'cart']})],
  modules: {
    user,
    search,
    cart,
    general,
  },
})

// Sync cart state between browser tabs
window.addEventListener('storage', event => {
  if (event.key === 'vuex') {
    const newState = JSON.parse(localStorage.vuex)

    if(JSON.stringify(newState.cart.cart) !== JSON.stringify(store.state.cart.cart)) {
      store.commit('setCart', newState.cart.cart)
    }
  }
})

export default store
