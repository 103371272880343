<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5002 0.0857849L15.9144 1.5L9.41394 7.99978L15.9144 14.5L14.5002 15.9142L7.99994 9.41378L1.50015 15.9142L0.0859375 14.5L6.58594 7.99978L0.0859375 1.5L1.50015 0.0857849L7.99994 6.58578L14.5002 0.0857849Z"
    />
  </svg>
</template>
