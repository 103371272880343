<template>
  <div class="total-result-pill" :class="{ small: small }">
    {{ count }} {{ $tc('result', +count) }}
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
    },
  },
}
</script>

<style lang="scss">
.total-result-pill {
  display: inline-flex;
  background-color: $color-black-primary;
  border-radius: 47px;
  color: white;
  font-size: 15px;
  line-height: 1;
  padding: 6px 10px;

  @include mq($until: mobile) {
    font-size: 12px;
  }

  &.small {
    font-size: 12px;
  }
}
</style>
