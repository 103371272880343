<template>
	<div class="language">
		<p class="language__choice" @click="switchLanguage('en')">EN</p>
		<div class="language__separator"></div>
		<p class="language__choice" @click="switchLanguage('de')">DE</p>
	</div>
</template>

<script>
export default {
	methods: {
		switchLanguage(lang) {
			this.$i18n.locale = lang
			this.$store.commit('setCurrentLanguage', lang)
		},
	}
}
</script>

<style lang="scss" scoped>
.language {
	display: flex;
	align-items: center;
	padding: 0 15px;

	&__choice {
		color: white;
		cursor: pointer;
		font-size: 14px;
    font-weight: 500;
		position: relative;
		padding: 3px 0;

		&:hover {
			&:after {
				width: 100%;
			}
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			width: 0;
			height: 1px;
			background-color: #fff;
			transition: all .1s linear;
			}
	}

	&__separator {
		display: block;
    width: 1px;
    height: 16px;
    background-color: #fff;
    margin: 0 6px;
	}
}
</style>