<template>
  <div class="search">
    <div class="search__trigger" @click="toggleSearchDropdown">
      <IconSearch v-if="!showSearchDropdown" />
      <IconCross v-else />
    </div>
    <div class="search__dropdown" v-show="showSearchDropdown">
      <SearchInput type="mobile" />
      <TotalResultPill small v-if="generalSearchText" :count="generalResultsCount" />
      <ul v-if="generalSearchText">
        <li
          :class="{ disabled: !generalResultsCount }"
          @click="toResultsPage('alle')"
        >
          <p>
            {{ $t('all') }}
            <span
              class="search__dropdown__count"
              :class="{ 'search__dropdown__count--invisible': !generalResults }"
            >
              ({{ generalResultsCount }})
            </span>
          </p>
          <IconArrowRight v-if="generalResultsCount" color="#262626" />
        </li>
        <li
          :class="{ disabled: !categoryCount(category.key) }"
          v-for="category in indexes"
          :key="category.key"
          @click="generalResults && toResultsPage(category.key)"
        >
          <p>
            {{ category.label }}
            <span
              class="search__dropdown__count"
              :class="{ 'search__dropdown__count--invisible': !generalResults }"
            >
              ({{ categoryCount(category.key) }})
            </span>
          </p>
          <IconArrowRight v-if="categoryCount(category.key)" color="#262626" />
        </li>
      </ul>
    </div>
    <div
      class="search__overlay"
      v-show="showSearchDropdown"
      @click="closeDropdown"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconArrowRight from '@/components/icons/IconArrowRight'
import IconCross from '@/components/icons/IconCross'
import IconSearch from '@/components/icons/IconSearch'
import TotalResultPill from '@/components/TotalResultPill.vue'
import SearchInput from '@/components/SearchInput.vue'

export default {
  components: {
    IconArrowRight,
    IconCross,
    IconSearch,
    TotalResultPill,
    SearchInput,
  },
  computed: {
    ...mapGetters([
      'showSearchDropdown',
      'indexes',
      'generalSearchText',
      'generalResults',
      'generalResultsCount',
    ]),
  },
  methods: {
    toggleSearchDropdown() {
      this.$store.commit('setShowSearchDropdown', !this.showSearchDropdown)

      if (this.showSearchDropdown)
        this.$store.commit('clearOtherDropdowns', 'showSearchDropdown')
    },
    closeDropdown() {
      this.$store.commit('setShowSearchDropdown', false)
    },
    toResultsPage(categoryKey) {
      this.$store.commit('setCurrentCategory', categoryKey)
      this.$router.push({ name: 'results' })
      this.closeDropdown()
    },
    categoryCount(categoryKey) {
      return this.generalResults
        ? this.generalResults.find((result) => result.index === categoryKey)
            .nbHits
        : 0
    },
  },
}
</script>

<style lang="scss" scoped>
.search {
  &__trigger {
    @include flex-center-align;
    margin-left: 30px;
    width: 20px;
    cursor: pointer;

    svg {
      fill: $color-nav-icons;
      width: 15px;
    }
  }

  &__dropdown {
    background-color: white;
    position: absolute;
    top: $navbar-height;
    left: 0;
    height: calc(100vh - $navbar-height);
    width: 100vw;
    z-index: $zIndex-navbar-dropdown;

    @include mq($from: mobile) {
      width: 470px;
    }

    .total-result-pill {
      line-height: 1;
      margin: 20px 16px;
    }

    ul {
      height: 100%;
      padding: 0 16px;
      overflow-y: scroll;

      li {
        @include flex-center-align;
        border-bottom: 1px solid $color-black-10;
        font-weight: $font-weight-medium;
        padding: 10px 0;

        .search__dropdown__count {
          font-size: 12px;
          font-weight: $font-weight-regular;
          margin-left: 4px;

          &--invisible {
            visibility: hidden;
          }
        }

        svg {
          margin-left: auto;
        }

        &:not(.disabled) {
          cursor: pointer;
        }

        &.disabled {
          color: $color-grey-500;
          pointer-events: none;

          .search__dropdown__count {
            color: $color-grey-500;
          }
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    top: $navbar-height;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: $color-black-20;
  }
}
</style>
