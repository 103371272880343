import { downloadAssets } from '@/api'

export default {
  state: () => ({
    newlyAddedItems: [],
    cart: [],
    isDownloading: false,
  }),
  actions: {
    async downloadAssets({ commit }, { title, variantIds }) {
      try {
        commit('setIsDownloading', true)
        const response = await downloadAssets(variantIds)
        let fileUrl = window.URL.createObjectURL(response.data)
        let fileLink = document.createElement('a')
        document.body.appendChild(fileLink)

        let fileName = title || `MDB_${Date.now()}`
        if(response.headers['content-disposition'] && response.headers['content-disposition'].indexOf('filename=') >= 0) {
          fileName = response.headers['content-disposition'].split('filename=')[1]
          fileName = fileName.replaceAll(/\\"/g, '')
          fileName = fileName.replaceAll('"', '')
        }

        fileLink.href = fileUrl
        fileLink.download = title || fileName
        fileLink.click()
        commit('setIsDownloading', false)
      } catch (error) {
        console.error(error)
      }
    },
  },
  mutations: {
    setNewlyAddedItems(state, payload) {
      state.newlyAddedItems = payload
    },
    clearNewlyAddedItems(state) {
      state.newlyAddedItems = []
    },
    addVariantToCart(state, payload) {
      // check if item is already in cart
      const itemIndex = state.cart.findIndex(
        (item) => item.objectID === payload.objectID,
      )
      if (itemIndex > -1) {
        // check if variant is already in item
        const variantIndex = state.cart[itemIndex].variants.findIndex(
          (variant) => variant.id === payload.variants[0].id,
        )
        if (variantIndex < 0)
          state.cart[itemIndex].variants.push(payload.variants[0])
      } else {
        state.cart.push(payload)
      }
    },
    removeVariantFromCart(state, { objectId, variantId }) {
      const itemIndex = state.cart.findIndex(
        (item) => item.objectID === objectId,
      )

      if (state.cart[itemIndex].variants.length > 1) {
        const replacementItem = {
          ...state.cart[itemIndex],
          variants: state.cart[itemIndex].variants.filter(
            (variant) => variant.id !== variantId,
          ),
        }

        state.cart[itemIndex] = replacementItem
      } else {
        // if last variant remaining, remove item from cart
        state.cart = state.cart.filter((item) => item.objectID !== objectId)
      }
    },
    addItemToCart(state, payload) {
      // check if item is already in cart
      const itemIndex = state.cart.findIndex(
        (item) => item.objectID === payload.objectID,
      )
      if (itemIndex < 0) state.cart.push(payload)
    },
    clearCart(state) {
      state.cart = []
    },
    setIsDownloading(state, payload) {
      state.isDownloading = payload
    },
    // Used for syncing cart state between tabs
    setCart(state, payload) {
      state.cart = payload
    },
  },
  getters: {
    newlyAddedItems(state) {
      return state.newlyAddedItems
    },
    cart(state) {
      return state.cart
    },
    cartCount(state) {
      return state.cart.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.variants.length
      }, 0)
    },
    isDownloading(state) {
      return state.isDownloading
    }
  },
}
