import { createRouter, createWebHistory } from 'vue-router'
import { publicPath } from '../../vue.config'
import Landing from '@/views/Landing.vue'
import Main from '@/views/Main.vue'
import MainGeneral from '@/components/MainGeneral.vue'
import MainCategory from '@/components/MainCategory.vue'
import store from '@/store'

const routes =
  process.env.VUE_APP_HAS_AUTH === 'true'
    ? [
        {
          path: '/',
          name: 'landing',
          component: Landing,
        },
        {
          path: '/set-password',
          name: 'set-password',
          component: () =>
            import(
              /* webpackChunkName: "set-password" */ '@/views/SetPassword.vue'
            ),
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: () =>
            import(
              /* webpackChunkName: "forgot-password" */ '@/views/ForgotPassword.vue'
            ),
        },
        {
          path: '/registration',
          name: 'registration',
          // route level code-splitting
          // this generates a separate chunk (registration.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(
              /* webpackChunkName: "registration" */ '@/views/Registration.vue'
            ),
        },
        {
          path: '/main',
          name: 'main',
          component: Main,
          beforeEnter(to, from, next) {
            if (store.getters.user) {
              next()
            } else {
              next({
                name: 'landing', // back to safety route
              })
            }
          },
          children: [
            {
              path: '/main',
              name: 'results',
              component: MainGeneral,
            },
            {
              path: ':category',
              name: 'category',
              component: MainCategory,
            },
            {
              path: 'account',
              name: 'account',
              component: () =>
                import(
                  /* webpackChunkName: "account" */ '@/components/MainAccount.vue'
                ),
            },
          ],
        },
      ]
    : [
        {
          path: '/',
          name: 'landing',
          component: Landing,
        },
        {
          path: '/main',
          name: 'main',
          component: Main,
          children: [
            {
              path: '/main',
              name: 'results',
              component: MainGeneral,
            },
            {
              path: ':category',
              name: 'category',
              component: MainCategory,
            },
          ],
        },
      ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: publicPath,
  routes,
})

export default router
