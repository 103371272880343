import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'

import { ElCheckbox, ElDialog, ElForm, ElInput, ElNotification, ElRadio, ElSelect } from 'element-plus'
import 'element-plus/es/components/checkbox/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/form/style/css'
import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/notification/style/css'
import 'element-plus/es/components/radio/style/css'
import 'element-plus/es/components/select/style/css'

import 'styles/index.scss'
import 'styles/element-overrides.scss'

const app = createApp(App)
const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'en',
  globalInjection: true,
  messages: loadLocaleMessages(),
})

app.use(router)
app.use(store)
app.use(i18n)
app.use(ElCheckbox)
app.use(ElDialog)
app.use(ElForm)
app.use(ElInput)
app.use(ElNotification)
app.use(ElRadio)
app.use(ElSelect)

app.config.globalProperties.$VUE_APP_BRAND_SLUG = process.env.VUE_APP_BRAND_SLUG;
app.config.globalProperties.$VUE_APP_HAS_AUTH = process.env.VUE_APP_HAS_AUTH === 'true' ? true : false;
app.config.globalProperties.$VUE_APP_HAS_TRANSLATIONS =
  process.env.VUE_APP_HAS_TRANSLATIONS === 'true' ? true : false
app.config.globalProperties.$VUE_APP_LANGUAGE_SWITCH =
  process.env.VUE_APP_LANGUAGE_SWITCH === 'true' ? true : false

app.mount('#app')

function loadLocaleMessages () {
  const locales = require.context(
    './locales/' + process.env.VUE_APP_BRAND_SLUG,
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
