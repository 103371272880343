import axios from 'axios'
import router from '@/router'
import store from '@/store'

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

baseApi.interceptors.request.use(
  async config => {
    if (store.getters.user && process.env.VUE_APP_HAS_AUTH === 'true') {
      config.headers['Authorization'] = `Bearer ${store.getters.user.token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
});

baseApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403) {
      store.commit('setUser', null)
      router.push({ path: '/' })
    }
    return Promise.reject(error)
});

export function getLinks() {
  return baseApi.get('/links')
}

export function getUserRoles() {
  return baseApi.get('/roles')
}

export function userSetPassword(payload) {
  return baseApi.get('/setpassword', {
    params: { ...payload },
  })
}

export function userResetPassword(payload) {
  return baseApi.get('/resetpassword', {
    params: { email: payload },
  })
}

export function userRegister(payload) {
  return baseApi.post('/register', payload)
}

export function userLogin(payload) {
  return baseApi.post('/login', payload)
}

export function userValidate() {
  return baseApi.post('/validate')
}

export function userUpdate(payload) {
  return baseApi.post('/update_user', payload)
}

export function userLogout() {
  return baseApi.get('/logout')
}

export function getKeyAndIndexes() {
  return baseApi.get('/filters')
}

export function downloadAssets(ids) {
  return baseApi.get('/download?ids=' + ids, {
    responseType: 'blob'
  })
}