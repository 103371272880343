<template>
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 15V18H16V15H18V20H0V15H2ZM10 0V10.936L14.29 6.64645L15.7042 8.06066L8.99711 14.7678L2.29 8.06066L3.70421 6.64645L8 10.942V0H10Z"
    />
  </svg>
</template>
