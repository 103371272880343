<template>
  <div class="add">
    <div class="add__header">
      <p class="add__header__text">
        {{ $t('add-cart-header') }}
      </p>
      <img @click="closeDialog" src="@/assets/images/cross.svg" class="add__header__close" />
    </div>
    <div class="add__products">
      <div class="add__product" v-for="item in newlyAddedItems" :key="item.objectID">
        <img class="add__product__image" :src="itemThumbnail(item)" />
        <div class="add__product__info">
          <p class="add__product__info__name">{{ item.title }}</p>
          <div class="add__product__type" v-for="variant in item.variants" :key="variant.id">
            <p class="add__product__type__format">{{ variant.extension }}</p>
            <p class="add__product__type__size">{{ variant.label }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="add__buttons">
      <Button outline :label="$t('button.continue-browsing')" @click="closeDialog" />
      <Button :label="$t('button.to-cart')" @click="toCart">
        <IconArrowRight />
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/Button.vue'
import IconArrowRight from '@/components/icons/IconArrowRight'

export default {
  components: {
    Button,
    IconArrowRight,
  },
  emits: ['closeDialog'],
  computed: {
    ...mapGetters(['newlyAddedItems', 'defaultThumbnails']),
  },
  methods: {
    itemThumbnail(item) {
      if (item.thumbnail.path) {
        return this.defaultThumbnails.assets_uri + item.thumbnail.path
      } else {
        return this.defaultThumbnails.assets_uri + this.defaultThumbnails.default_thumbnails[item.thumbnail.ext]
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
      this.$store.commit('clearNewlyAddedItems')
    },
    toCart() {
      this.closeDialog()
      this.$store.commit('setShowCart', true)
    }
  },
}
</script>

<style lang="scss">
.add {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 150px);
  max-width: calc(100vw - 60px);
  width: 500px;

  .elf & {
    width: 600px;
  }

  &__header {
    @include flex-center-align;
    justify-content: space-between;
    border-bottom: 1px solid $color-grey-300;
    padding: 20px;

    &__text {
      font-size: 16px;
      font-weight: $font-weight-medium;
      word-break: break-word;

      @include mq($from: mobile) {
        font-size: 18px;
      }
    }

    &__close {
      margin-left: 20px;
      cursor: pointer;
    }
  }

  &__products {
    padding: 20px;
    overflow-y: scroll;
  }

  &__product {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;

    &__image {
      width: 80px;
    }

    &__info {
      width: 100%;

      &__name {
        font-weight: $font-weight-medium;
        margin-bottom: 10px;
      }
    }

    &__type {
      display: flex;
      border-bottom: 1px solid $color-black-10;
      font-size: 14px;
      padding: 6px 0;

      &__format {
        font-stretch: condensed;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        white-space: nowrap;
      }

      &__size {
        flex: 100%;
        margin-left: 8px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
  }
}
</style>
