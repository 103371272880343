<template>
  <div class="landing-search">
    <Navigation isLanding />
    <div class="landing-search__center">
      <div class="landing-search__above">
        <p class="landing-search__above__text">
          {{
            $t('welcome', { name: $VUE_APP_HAS_AUTH ? user.first_name : '' })
          }}
        </p>
        <TotalResultPill
          v-if="!isTablet && generalSearchText && generalResultsCount"
          :count="generalResultsCount"
        />
      </div>
      <SearchInput type="landing" />
      <p class="landing-search__example">
        {{ $t('search-suggestion') }}
      </p>
      <div class="landing-search__categories">
        <div
          class="landing-search__category"
          :class="{
            'landing-search__category--disabled': !categoryCount(category.key),
          }"
          v-for="category in indexes"
          :key="category.key"
          @click="generalResults && toResultsPage(category.key)"
        >
          <p class="landing-search__category__text">
            {{ category.label }}
            <span
              class="landing-search__category__count"
              :class="{
                'landing-search__category__count--invisible': !generalResults,
              }"
            >
              {{ `(${categoryCount(category.key)})` }}
            </span>
          </p>
          <span class="landing-search__category__underline" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SearchInput from '@/components/SearchInput.vue'
import TotalResultPill from '@/components/TotalResultPill.vue'
import Navigation from '@/components/Navigation.vue'

export default {
  components: {
    SearchInput,
    TotalResultPill,
    Navigation,
  },
  mounted() {
    if (this.generalSearchText !== '') {
      this.$store.commit('setGeneralSearchText', '')
      this.$emit('retrigger-search')
    }
    // this.$store.commit('clearGeneralResults')
  },
  computed: {
    ...mapGetters([
      'isTablet',
      'user',
      'indexes',
      'generalSearchText',
      'generalResults',
      'generalResultsCount',
    ]),
  },
  methods: {
    toResultsPage(categoryKey) {
      this.$store.commit('setCurrentCategory', categoryKey)
      this.$router.push({ name: 'results' })
    },
    categoryCount(categoryKey) {
      return this.generalResults
        ? this.generalResults.find((result) => result.index === categoryKey)
            ?.nbHits
        : 0
    },
  },
}
</script>

<style lang="scss">
.landing-search {
  @include flex-center;
  width: 100%;

  .nav {
    transform: translateY(-$navbar-height);
    animation: fadeInDown 0.2s linear 0.4s forwards;
    background: $color-nav-landing;
  }

  &__center {
    margin: 0 16px;
    width: 100%;
    max-width: 1000px;
    transform: translateY($navbar-height);
    animation: fadeInUp 0.4s linear forwards;
  }

  &__above {
    @include flex-center-align;
    justify-content: space-between;
    height: 32px;
    margin-bottom: 12px;
    width: 100%;
    position: absolute;
    top: -44px; // height + margin-bottom

    &__text {
      color: white;
    }
  }

  &__example {
    color: white;
    font-size: 12px;
    margin-top: 10px;

    @include mq($from: tablet) {
      display: none;
    }
  }

  .search {
    z-index: $zIndex-landing-search;
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    gap: 26px;
    background-color: $color-landing-categories;
    border-radius: 4px;
    margin-top: 20px;
    padding: 20px 20px 16px;
    opacity: 0;
    transform: translateY(-$navbar-height);
    animation: fadeInDown 0.2s linear 0.4s forwards;
    position: absolute;
    z-index: -1;

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__category {
    display: flex;
    flex-direction: column;
    color: var(--color-pink);
    cursor: pointer;

    &__count {
      color: var(--color-pink);
      font-size: 12px;
      margin-left: 2px;

      &--invisible {
        visibility: hidden;
      }
    }

    &:hover {
      > span {
        transform: scaleX(1);
        width: 100%;
      }
    }

    &__underline {
      background-color: var(--color-pink);
      height: 1px;
      transition: all 0.3s ease;
      transform: scaleX(0);
      transform-origin: left center;
      width: 0;
    }

    &--disabled {
      color: $color-white-40;
      pointer-events: none;

      .landing-search__category__count {
        color: $color-white-40;
        font-size: 12px;
        margin-left: 2px;
      }
    }
  }
}

@keyframes fadeInDown {
  0% {
    transform: translateY(-$navbar-height);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY($navbar-height);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
