<template>
  <div class="menu">
    <div class="menu__hamburger" v-if="isTablet" @click="toggleMenuDropdown">
      <IconHamburger v-if="!showMenuDropdown" />
      <IconCross v-else />
    </div>
    <div class="menu__trigger" v-else @click="toggleMenuDropdown">
      <p>{{ $t('mediadatabase') }}</p>
      <IconCaretDown v-if="!showMenuDropdown" />
      <IconCaretUp v-else />
    </div>
    <Transition name="fade-in">
      <div class="menu__dropdown" v-show="showMenuDropdown">
        <div
          class="menu__dropdown__item"
          v-for="category in indexes"
          :key="category.key"
          @click="toCategoryPage(category)"
        >
          <h4 class="menu__dropdown__item__category">
            {{ category.label }}<span />
          </h4>
        </div>
        <div class="menu__dropdown__external">
          <a v-if="mainWebsiteLink" class="menu__dropdown__external__link" :href="mainWebsiteLink.value">
            <img src="@/assets/images/arrow-left.svg" />
            <p>
              {{ $t('link.to-website', { website: mainWebsiteLink.label }) }}
            </p>
          </a>
          <template v-if="$VUE_APP_HAS_TRANSLATIONS && isTablet">
            <LanguageSwitch v-if="$VUE_APP_LANGUAGE_SWITCH"/>
            <LanguageDropup v-else />
          </template>
        </div>
      </div>
    </Transition>
    <Transition name="fade-in">
      <div
        class="menu__overlay"
        v-show="showMenuDropdown"
        @click="closeDropdown"
      />
    </Transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconCaretDown from '@/components/icons/IconCaretDown'
import IconCaretUp from '@/components/icons/IconCaretUp'
import IconCross from '@/components/icons/IconCross'
import IconHamburger from '@/components/icons/IconHamburger'
import LanguageSwitch from '@/components/LanguageSwitch.vue'
import LanguageDropup from '@/components/dropdowns/LanguageDropup.vue'

export default {
  components: {
    IconCaretDown,
    IconCaretUp,
    IconCross,
    IconHamburger,
    LanguageSwitch,
    LanguageDropup,
  },
  computed: {
    ...mapGetters([
      'mainWebsiteLink',
      'isTablet',
      'showMenuDropdown',
      'indexes',
      'generalResults',
      'currentCategory',
    ]),
  },
  methods: {
    toggleMenuDropdown() {
      this.$store.commit('setShowMenuDropdown', !this.showMenuDropdown)

      if (this.showMenuDropdown)
        this.$store.commit('clearOtherDropdowns', 'showMenuDropdown')
    },
    closeDropdown() {
      this.$store.commit('setShowMenuDropdown', false)
    },
    toCategoryPage(category) {
      this.$store.commit('setGeneralSearchText', '')
      this.$store.commit('setCurrentCategory', category.key)
      this.$router.push({
        name: 'category',
        params: { category: category.key },
      })
      this.$store.commit('setShowMenuDropdown', false)
    },
  },
}
</script>

<style lang="scss">
.menu {
  @include flex-center-align;

  &__hamburger {
    @include flex-center-align;
    cursor: pointer;
    margin-left: 18px;

    svg {
      fill: $color-nav-icons;
      width: 20px;
    }
  }

  &__trigger {
    @include flex-center-align;
    font-size: 13px;
    font-weight: $font-weight-bold;
    letter-spacing: 2px;
    line-height: 32px;
    text-transform: uppercase;
    margin-left: 30px;
    cursor: pointer;
    gap: 10px;
    width: 178px;
    color: $color-nav-icons;

    @include mq($from: desktop) {
      margin-left: 60px;
    }

    svg {
      fill: $color-nav-icons;
    }
  }

  &__dropdown {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $navbar-height;
    left: 0;
    height: calc(100vh - $navbar-height);
    overflow-y: scroll;
    padding: 40px 40px 40px 16px;
    z-index: $zIndex-navbar-dropdown;

    @include mq($until: mobile) {
      max-width: 95%;
    }

    @include mq($from: tablet, $until: tablet-landscape) {
      padding: 60px 60px 60px 40px;
    }

    @include mq($from: tablet-landscape) {
      padding: 60px 160px 60px 40px;
    }

    &__item {
      display: flex;
      cursor: pointer;
      margin-bottom: 14px;
      max-width: 100%;

      @include mq($from: mobile) {
        margin-bottom: 24px;
        width: fit-content;
      }

      @include mq($from: tablet, $and: '(max-height: 1000px)') {
        margin-bottom: 18px;
      }

      &__category {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @include mq($until: mobile) {
          display: inline;

          span {
            display: none;
          }
        }

        @include mq($from: mobile, $until: tablet) {
          font-size: 40px;
        }

        @include mq($from: tablet, $and: '(max-height: 1000px)') {
          font-size: 22px;
        }

        &:hover {
          span {
            transform: scaleX(1);
            width: 100%;
          }
        }

        span {
          background-color: $color-primary;
          height: 3px;
          transition: all 0.3s ease;
          transform: scaleX(0);
          transform-origin: left center;
          width: 0;
        }
      }

      &__count {
        background-color: $color-black-primary;
        border-radius: 47px;
        color: white;
        display: inline-flex;
        font-size: 11px;
        font-weight: $font-weight-medium;
        line-height: 1;
        height: min-content;
        margin-left: 8px;
        padding: 3px 7px;

        @include mq($from: mobile, $until: tablet) {
          font-size: 13px;
        }

        @include mq($from: tablet) {
          font-size: 15px;
          padding: 4px 10px;
        }
      }
    }

    &__external {
      display: flex;
      justify-content: space-between;
      column-gap: 8px;
      margin-top: auto;
      position: relative;

      &__link {
        display: flex;
        gap: 12px;
        align-self: flex-end;
        height: fit-content;
        text-decoration: none;

        &:hover {
          img {
            transform: translateX(-4px);
          }
        }

        p {
          border-bottom: 2px solid $color-primary;
          color: $color-black-primary;
          font-size: 14px;
          font-weight: $font-weight-medium;
          padding-bottom: 4px;
        }
      }
    }

    .language {
      &__choice {
        color: $color-black-primary;

        &:after {
          background-color: $color-black-primary;
        }
      }
      &__separator {
        background-color: $color-black-primary;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: $navbar-height;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: $color-black-20;
  }
}

.fade-in-enter-active {
  transition: opacity 0.3s;
}

.fade-in-enter-from {
  opacity: 0;
}
</style>
