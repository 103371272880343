<template>
  <div :id="filter.key" class="filter" v-if="Object.keys(values).length">
    <div
      class="filter__trigger"
      :class="{
        'filter__trigger--open': openFilter,
        'filter__trigger--active': savedValuesLength,
      }"
      @click="toggleFilter"
    >
      <p>{{ filter.singular_label[currentLanguage] }}</p>
      <div v-if="savedValuesLength" class="filter__trigger__count">
        {{ savedValuesLength }}
      </div>
      <div v-if="!isTablet" class="filter__trigger__icon">
        <img
          v-if="!openFilter"
          src="@/assets/images/chevron-down.svg"
        />
        <img
          v-else
          src="@/assets/images/chevron-up.svg"
        />
      </div>
    </div>
    <div v-if="!isTablet" v-show="openFilter">
      <div
        class="filter__neck"
        :class="{ 'filter__neck--active': savedValuesLength }"
      />
      <div
        class="filter__body"
        :class="{ 'filter__body--active': savedValuesLength }"
      >
        <div class="filter__body__selection">
          <ul>
            <li
              v-for="value in Object.keys(values)"
              :key="value"
              @click="toggleCheckbox(value)"
            >
              {{ value }}
              <el-checkbox
                class="checkbox-filter"
                v-model="values[value]"
                @click.prevent
              />
            </li>
          </ul>
        </div>
        <div v-if="savedValuesLength || activeValues.length" class="filter__body__buttons">
          <button class="outline" @click="resetFilter">
            {{ $t('button.reset') }}
          </button>
          <button class="solid" @click="saveFilter">
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>
    <el-dialog v-if="isTablet" v-model="openFilter" :show-close="false">
      <div class="filter__body">
        <div class="filter__body__header">
          <img src="@/assets/images/filter.svg" />
          <p>{{ filter.singular_label[currentLanguage] }}</p>
          <div v-if="savedValuesLength" class="filter__body__header__count">
            {{ savedValuesLength }}
          </div>
          <img
            class="filter__body__header__close"
            @click="closeFilter"
            src="@/assets/images/cross.svg"
          />
        </div>
        <div class="filter__body__selection">
          <ul>
            <li
              v-for="value in Object.keys(values)"
              :key="value"
              @click="toggleCheckbox(value)"
            >
              {{ value }}
              <el-checkbox
                class="checkbox-filter"
                v-model="values[value]"
                @click.prevent
              />
            </li>
          </ul>
        </div>
        <div class="filter__body__buttons">
          <button class="outline" @click="resetFilter">
            {{ $t('button.reset') }}
          </button>
          <button class="solid" @click="saveFilter">
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  emits: ['setFilter'],
  props: {
    filter: {
      type: Object,
    },
  },
  data() {
    return {
      openFilter: false,
      initialValues: {},
      values: {},
      savedValues: {},
    }
  },
  mounted() {
    if (this.filter.facetHits) this.initialiseValues()

    window.addEventListener('click', this.clickOutside)
  },
  beforeUnmount() {
    window.removeEventListener('click', this.clickOutside)
  },
  computed: {
    ...mapGetters(['isTablet', 'currentLanguage']),
    activeValues() {
      let activeValues = []
      Object.keys(this.values).forEach((value) => {
        if (this.values[value]) activeValues.push(value)
      })
      return activeValues
    },
    savedValuesLength() {
      return Object.values(this.savedValues).filter((value) => value).length
    },
  },
  watch: {
    'filter.facetHits'() {
      this.initialiseValues()
    },
    openFilter() {
      if (!this.openFilter) this.values = { ...this.savedValues }
    },
  },
  methods: {
    clickOutside(e) {
      if (!document.getElementById(this.filter.key)?.contains(e.target)){
        // Clicked outside the box
        this.openFilter = false
      }
    },
    closeFilter() {
      this.openFilter = false
    },
    toggleFilter() {
      this.openFilter = !this.openFilter
    },
    toggleCheckbox(value) {
      this.values[value] = !this.values[value]
    },
    initialiseValues() {
      if(!this.filter || !this.filter.facetHits) return
      this.filter.facetHits.forEach(
        (facet) => (this.initialValues[facet.value] = false),
      )
      this.values = { ...this.initialValues }
      this.savedValues = { ...this.initialValues }
    },
    resetFilter() {
      this.values = { ...this.initialValues }

      const areFiltersSet = Object.values(this.savedValues).some(
        (value) => value,
      )
      if (areFiltersSet) {
        this.savedValues = { ...this.initialValues }
        this.$emit('setFilter', {
          name: this.filter.key,
          values: this.activeValues,
        })
      }

      this.closeFilter()
    },
    saveFilter() {
      this.savedValues = { ...this.values }
      this.$emit('setFilter', {
        name: this.filter.key,
        values: this.activeValues,
      })

      this.closeFilter()
    },
  },
}
</script>

<style lang="scss">
.filter {
  position: relative;

  &__trigger {
    @include flex-center-align;
    border: 1px solid $color-black-primary;
    border-radius: 2px;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    padding: 8px 10px;
    white-space: nowrap;

    @include mq($from: tablet) {
      border: 1px solid $color-black-primary;
      font-size: 16px;
      height: 50px;
      padding: 14px 16px;
    }

    &__count {
      background-color: $color-black-primary;
      color: white;
      font-size: 12px;
      font-weight: $font-weight-bold;
      line-height: 1;
      margin-left: 6px;
      padding: 3px 5px;
    }

    &__icon {
      height: 10px;
      width: 15px;
      margin-left: 20px;
      pointer-events: none;
      display: flex;
    }

    &--open {
      @include mq($from: tablet) {
        background-color: white;
        border-bottom-color: transparent;
      }
    }

    &--active {
      @include mq($from: tablet) {
        border-width: 2px;
      }
    }
  }

  &__neck {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    background-color: white;
    border-left: 1px solid $color-black-primary;
    border-right: 1px solid $color-black-primary;
    height: 10px;
    z-index: $zIndex-filter-neck;

    &--active {
      @include mq($from: tablet) {
        border-width: 2px;
      }
    }
  }

  &__body {
    min-width: 259px;
    z-index: $zIndex-filter-body;

    @include mq($from: tablet) {
      position: absolute;
      top: calc(100% + 7px);
      border: 1px solid $color-black-primary;
    }

    &--active {
      @include mq($from: tablet) {
        border-width: 2px;
      }
    }

    &__header {
      @include flex-center-align;
      gap: 8px;
      border-bottom: 1px solid $color-black-10;
      padding: 20px;

      &__count {
        background-color: $color-black-primary;
        color: white;
        font-size: 12px;
        font-weight: $font-weight-bold;
        line-height: 1;
        padding: 4px 5px;
      }

      &__close {
        cursor: pointer;
        margin-left: auto;
      }
    }

    &__selection {
      background-color: white;
      padding: 16px 0;

      ul li {
        @include flex-center-align;
        cursor: pointer;
        justify-content: space-between;
        list-style: none;
        padding: 8px 16px;
      }
    }

    &__buttons {
      display: flex;
      border-top: 1px solid $color-black-primary;

      button {
        border: none;
        cursor: pointer;
        flex: 1;
        font-size: 14px;
        line-height: 24px;
        padding: 8px 28px;
        white-space: nowrap;
      }

      button.outline {
        background-color: white;
      }
      button.solid {
        background-color: $color-black-primary;
        color: white;
      }
    }
  }
}
</style>
