<template>
  <div class="language">
    <div class="language__trigger" @click="toggleDropdown">
      <img :src="showCurrentLanguageFlag" />
    </div>
    <div class="language__dropdown" v-show="showLanguageDropdown">
      <ul>
        <li v-if="currentLanguage !== 'en'" @click="switchLanguage('en')">
          {{ $t('english') }}<img src="@/assets/images/flag-uk.svg" />
        </li>
        <li v-if="currentLanguage !== 'de'" @click="switchLanguage('de')">
          {{ $t('german') }}<img src="@/assets/images/flag-austria.svg" />
        </li>
      </ul>
    </div>
    <div
      class="language__overlay"
      v-show="showLanguageDropdown"
      @click="closeDropdown"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentLanguage', 'showLanguageDropdown']),
    showCurrentLanguageFlag() {
      switch (this.currentLanguage) {
        case 'en':
          return require('@/assets/images/flag-uk.svg')
        case 'de':
          return require('@/assets/images/flag-austria.svg')
        default:
          return require('@/assets/images/flag-austria.svg')
      }
    },
  },
  methods: {
    toggleDropdown() {
      this.$store.commit('setShowLanguageDropdown', !this.showLanguageDropdown)

      if (this.showLanguageDropdown) this.$store.commit('clearOtherDropdowns', 'showLanguageDropdown')
    },
    closeDropdown() {
      this.$store.commit('setShowLanguageDropdown', false)
    },
    switchLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('setCurrentLanguage', lang)
      this.closeDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>
.language {
  @include flex-center-align;
  padding: 0 15px;

  &__trigger {
    @include flex-center-align;
    gap: 8px;
    cursor: pointer;
  }

  &__dropdown {
    background-color: white;
    position: absolute;
    top: $navbar-height;
    right: 0;
    padding: 30px;
    z-index: $zIndex-navbar-dropdown;

    ul {
      display: flex;
      flex-direction: column;
      gap: 15px;

      li {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        list-style: none;
        cursor: pointer;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    top: $navbar-height;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: $color-black-20;
  }
}
</style>
