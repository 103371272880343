<template>
  <div class="landing-auth">
    <Navigation isRegistration />
    <div class="landing-auth__box">
      <IconUser class="landing-auth__box__icon" />
      <p class="landing-auth__title">{{ $t('login.title') }}</p>
      <el-form label-position="top" :model="$data" :rules="$options.rules">
        <el-form-item :label="$t('label.email')" @change="validateEmail"
          :class="{ 'landing-auth__box__error': error.email }" @keyup.enter="login">
          <el-input v-model="email" :placeholder="$t('label.email')" />
          <p v-if="error.email" class="landing-auth__box__error__text">
            {{ $t('error.email') }}
          </p>
        </el-form-item>
        <el-form-item :label="$t('label.password')">
          <el-input v-model="password" :placeholder="$t('label.password')" show-password @keyup.enter="login" />
        </el-form-item>
      </el-form>
      <a class="landing-auth__box__link" target="_blank" @click="toForgotPasswordPage">{{ $t('login.forgot-password')
      }}</a>
      <div class="landing-auth__box__message">
        <p v-if="userLoginError" v-html="userLoginError"></p>
      </div>
      <Button @click="login" type="submit">
        <Loader v-if="user && !indexes" />
        <span v-else>
          {{ $t('button.login') }}
        </span>
      </Button>
    </div>
    <div class="landing-auth__box">
      <img class="landing-auth__box__icon" src="@/assets/images/user-register.svg" />
      <p class="landing-auth__title">{{ $t('register.landing.title') }}</p>
      <ul>
        <li>{{ $t('register.landing.advantage-1') }}</li>
        <li>{{ $t('register.landing.advantage-2') }}</li>
        <li>{{ $t('register.landing.advantage-3') }}</li>
      </ul>
      <p class="landing-auth__text--grey">
        {{ $t('register.landing.paragraph') }}
      </p>
      <Button :label="$t('button.register-now')" @click="toRegisterPage" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElNotification } from 'element-plus'

import Button from '@/components/Button.vue'
import Loader from '@/components/Loader'
import Navigation from '@/components/Navigation.vue'
import IconUser from '@/components/icons/IconUser'

export default {
  components: {
    Button,
    Loader,
    Navigation,
    IconUser,
  },
  data() {
    return {
      email: '',
      password: '',
      error: {
        email: '',
      },
    }
  },
  mounted() {
    if (this.userSetPassword) this.setPasswordNotification()
    if (this.userResetPassword) this.resetPasswordNotification()

    if (this.user) this.$store.commit('setUser', null)
    if (this.userLoginError) this.$store.commit('setUserLoginError', '')
  },
  computed: {
    ...mapGetters(['user', 'userLoginError', 'userSetPassword', 'userResetPassword', 'indexes']),
  },
  watch: {
    // clear error message when form is changed
    $data: {
      handler() {
        if (this.user) this.$store.commit('setUser', null)
        if (this.userLoginError) this.$store.commit('setUserLoginError', '')
      },
      deep: true
    }
  },
  methods: {
    validateEmail() {
      if (
        (this.email && !this.email.includes('@')) ||
        !this.email.includes('.')
      ) {
        this.error.email = true
      } else {
        this.error.email = false
      }
    },
    login() {
      this.$store.dispatch('userLogin', {
        username: this.email,
        password: this.password,
      })
    },
    setPasswordNotification() {
      ElNotification({
        title: this.$t('notification.changes-saved'),
        position: 'bottom-right',
        duration: 2000,
        type: 'success',
        showClose: false,
      })

      this.$store.commit('setUserSetPassword', false)
    },
    resetPasswordNotification() {
      ElNotification({
        title: this.$t('notification.password-reset'),
        position: 'bottom-right',
        duration: 5000,
        type: 'success',
        showClose: false,
      })

      this.$store.commit('setUserResetPassword', false)
    },
    toForgotPasswordPage() {
      this.$router.push({ name: 'forgot-password' })
    },
    toRegisterPage() {
      this.$store.commit('setUserRegistered', false)
      this.$store.commit('setUserRegisterError', '')

      this.$router.push({ name: 'registration' })
    },
  },
}
</script>

<style lang="scss">
.landing-auth {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  padding-top: 100px !important; // because of navbar
  max-width: 1000px;

  @include mq($from: mobile, $until: tablet) {
    margin: $navbar-height 40px 0;
    padding: 20px 40px;
  }

  @include mq($from: tablet, $until: desktop) {
    margin: $navbar-height 60px 0;
    padding: 40px 60px;
  }

  @include mq($from: desktop) {
    margin: $navbar-height 80px 0;
    padding: 80px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $color-grey-200;
    padding: 16px;
    position: relative;
    min-width: 320px;
    max-width: 500px;

    @include mq($from: tablet, $until: desktop) {
      padding: 30px;
    }

    @include mq(tablet-landscape) {
      width: 50%;
    }

    @include mq($from: desktop) {
      padding: 60px;
    }

    &__icon {
      align-self: flex-start;
      height: 22px;
    }

    &__link {
      display: flex;
      align-self: flex-end;
      font-size: 16px;
      color: $color-black-primary;
      cursor: pointer;
      border-bottom: 2px solid $color-primary;
      margin-bottom: 20px;
      padding: 8px 0;
      text-decoration: none;

      &::after {
        clear: both;
      }
    }

    &__error {
      input {
        border-color: $color-error;
      }

      &__text {
        color: $color-error;
        font-size: 14px;
        line-height: 14px;
        padding-top: 4px;
      }
    }

    &__message {
      color: $color-error;
      max-width: 343px;
      min-height: 44px;
      margin-bottom: 20px;
    }

    ul {
      list-style-position: outside;
      margin-left: 12px;
      margin-bottom: 24px;
      color: $color-grey-700;

      li::marker {
        color: $color-primary;
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: $font-weight-bold;
    margin: 20px 0 24px;

    .elf & {
      font-size: 20px;
      line-height: 1.4;
      text-transform: uppercase;
    }
  }

  &__text--grey {
    color: $color-grey-700;
    margin-bottom: auto;
  }
}
</style>
