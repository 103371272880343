<template>
  <div class="selection" v-if="totalSelected > 0">
    <p class="selection__text">
      {{
        `${totalSelected} ${$tc('elements', totalSelected)} ${$t('selected')}`
      }}
    </p>
    <div class="selection__buttons">
      <Button :class="{'loading': isDownloading}" @click="$emit('download')">
        <Loader v-if="isDownloading" />
        <template v-else>
          <IconDownload />
          <span>{{ $t('button.download') }}</span>
        </template>
      </Button>
      <Button :label="$t('button.add')" @click="$emit('addToCart')">
        <IconCart />
      </Button>
      <Button outline @click="$emit('deselectAll')">
        <IconBin />
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/Loader'
import Button from '@/components/Button.vue'

import IconBin from '@/components/icons/IconBin'
import IconCart from '@/components/icons/IconCart'
import IconDownload from '@/components/icons/IconDownload'

export default {
  components: {
    Loader,
    Button,
    IconBin,
    IconCart,
    IconDownload,
  },
  props: {
    totalSelected: {
      type: Number,
    },
  },
  emits: ['download', 'addToCart', 'deselectAll'],
  computed: {
    ...mapGetters(['isDownloading']),
  },
}
</script>

<style lang="scss">
.selection {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  width: 100%;
  z-index: $zIndex-selection-modal;

  @include mq($from: mobile) {
    left: auto;
    right: 20px;
    bottom: 20px;
    padding: 24px;
    width: fit-content;
  }

  &__text {
    font-size: 14px;
    font-weight: $font-weight-medium;
    margin-bottom: 16px;

    @include mq($from: mobile) {
      font-size: 18px;
      margin-bottom: 24px;
    }
  }

  &__buttons {
    display: flex;
    gap: 6px;

    .button {
      position: relative;
      
      @include mq($until: 390px) {
        padding: 10px !important;
      }

      @include mq($until: 353px) {
        &.solid {
          svg {
            margin-right: 8px !important;
          }
        }
        svg {
          width: 12px;
        }
      }

      &.loading {
        .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        svg, p {
          visibility: hidden;
        }
      }
    }

    .button--outline svg {
      margin: 0;
    }
  }
}
</style>
