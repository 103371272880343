import { getLinks } from '@/api'

export default {
  state: () => ({
    backgroundImage: '',
    mainWebsiteLink: null, // object with label and value
    termsConditionsLink: null,
    contactLink: null,
    isTablet: false,
    showCart: false,
    showAccountDropdown: false,
    showLanguageDropdown: false,
    showMenuDropdown: false,
    showSearchDropdown: false,
  }),
  actions: {
    async getLinks({ commit }) {
      try {
        const response = await getLinks()
        commit('setBackgroundImage', response.data.background)
        commit('setTermsConditionsLink', response.data.terms_conditions)
        commit('setMainWebsiteLink', {
          label: response.data.main_website_short,
          value: response.data.main_website_full,
        })
        commit('setContactLink', response.data.contact_mail)
      } catch (error) {
        console.error(error)
      }
    },
  },
  mutations: {
    setBackgroundImage(state, payload) {
      state.backgroundImage = payload
    },
    setMainWebsiteLink(state, payload) {
      state.mainWebsiteLink = payload
    },
    setTermsConditionsLink(state, payload) {
      state.termsConditionsLink = payload
    },
    setContactLink(state, payload) {
      state.contactLink = payload
    },
    setIsTablet(state, payload) {
      state.isTablet = payload
    },
    setShowCart(state, payload) {
      state.showCart = payload
    },
    setShowAccountDropdown(state, payload) {
      state.showAccountDropdown = payload
    },
    setShowLanguageDropdown(state, payload) {
      state.showLanguageDropdown = payload
    },
    setShowMenuDropdown(state, payload) {
      state.showMenuDropdown = payload
    },
    setShowSearchDropdown(state, payload) {
      state.showSearchDropdown = payload
    },
    clearOtherDropdowns(state, payload) {
      state.showCart = false
      state.showAccountDropdown = false
      state.showLanguageDropdown = false
      state.showMenuDropdown = false
      state.showSearchDropdown = false

      state[payload] = true
    },
    clearAllDropdowns(state) {
      state.showCart = false
      state.showAccountDropdown = false
      state.showLanguageDropdown = false
      state.showMenuDropdown = false
      state.showSearchDropdown = false
    },
  },
  getters: {
    backgroundImage(state) {
      return state.backgroundImage
    },
    mainWebsiteLink(state) {
      return state.mainWebsiteLink
    },
    termsConditionsLink(state) {
      return state.termsConditionsLink
    },
    contactLink(state) {
      return state.contactLink
    },
    isTablet(state) {
      return state.isTablet
    },
    showCart(state) {
      return state.showCart
    },
    showAccountDropdown(state) {
      return state.showAccountDropdown
    },
    showLanguageDropdown(state) {
      return state.showLanguageDropdown
    },
    showMenuDropdown(state) {
      return state.showMenuDropdown
    },
    showSearchDropdown(state) {
      return state.showSearchDropdown
    },
  },
}
