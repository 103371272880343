<template>
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 0H0L6 9L12 0Z" />
  </svg>
</template>
