<template>
  <div class="nav">
    <div class="nav__left">
      <div
        v-if="isRegistration || !isTablet"
        @click="backToLanding"
        class="nav__left__logo"
      >
        <img
          :src="require(`@/assets/images/logo/${$VUE_APP_BRAND_SLUG}-logo.svg`)"
        />
      </div>
      <MenuDropdown v-if="!isRegistration" />
      <p v-else class="nav__left__menu">{{ $t('mediadatabase') }}</p>
      <SearchDropdown v-if="!isRegistration && isTablet" />
    </div>
    <div class="nav__center" v-if="!isRegistration">
      <SearchInput v-if="showSearchInput" type="nav" />
      <img
        :src="
          require(`@/assets/images/logo/${$VUE_APP_BRAND_SLUG}-logo-mobile.svg`)
        "
        v-if="isTablet"
        @click="backToLanding"
        class="nav__center__logo"
      />
    </div>
    <div class="nav__right" v-if="!isRegistration">
      <template v-if="$VUE_APP_HAS_AUTH">
        <AccountDropdown />
      </template>
      <CartDropdown />
      <template v-if="$VUE_APP_HAS_TRANSLATIONS && !isTablet">
        <LanguageSwitch v-if="$VUE_APP_LANGUAGE_SWITCH" />
        <LanguageDropdown v-else />
      </template>
    </div>
    <div class="nav__right" v-if="$VUE_APP_HAS_TRANSLATIONS && isRegistration">
      <LanguageSwitch v-if="$VUE_APP_LANGUAGE_SWITCH" />
      <LanguageDropdown v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SearchInput from '@/components/SearchInput.vue'
import LanguageSwitch from '@/components/LanguageSwitch.vue'
import MenuDropdown from '@/components/dropdowns/MenuDropdown.vue'
import SearchDropdown from '@/components/dropdowns/SearchDropdown.vue'
import AccountDropdown from '@/components/dropdowns/AccountDropdown.vue'
import LanguageDropdown from '@/components/dropdowns/LanguageDropdown.vue'
import CartDropdown from '@/components/dropdowns/CartDropdown.vue'

export default {
  components: {
    SearchInput,
    LanguageSwitch,
    MenuDropdown,
    SearchDropdown,
    AccountDropdown,
    LanguageDropdown,
    CartDropdown,
  },
  props: {
    isLanding: {
      type: Boolean,
      default: false,
    },
    isRegistration: {
      type: Boolean,
      default: false,
    },
    isMain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLandingSearchInput: false,
    }
  },
  computed: {
    ...mapGetters(['isTablet', 'showMenuDropdown']),
    showSearchInput() {
      if (!this.isTablet) {
        if (this.isLanding) return this.showLandingSearchInput
        return true
      }
      return false
    },
  },
  watch: {
    showMenuDropdown() {
      if (this.isLanding) this.showLandingSearchInput = this.showMenuDropdown
    },
  },
  methods: {
    backToLanding() {
      this.$router.push({ path: '/' })
    },
  },
}
</script>

<style lang="scss">
.nav {
  @include flex-center-align;
  justify-content: space-between;
  background: $color-nav-full;
  border-bottom: 1px solid $color-black-10;
  height: $navbar-height;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zIndex-navbar;

  &__left {
    @include flex-center-align;
    height: 100%;

    &__logo {
      background: $color-nav-box;
      cursor: pointer;
      height: 100%;
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 70%;
      }
    }

    &__menu {
      color: $color-nav-icons;
      font-size: 13px;
      font-weight: $font-weight-bold;
      letter-spacing: 2px;
      line-height: 32px;
      text-transform: uppercase;
      margin-left: 30px;

      @include mq($from: mobile) {
        margin-left: 60px;
      }
    }
  }

  &__center {
    @include mq($from: tablet) {
      width: 100%;
    }

    &__logo {
      cursor: pointer;
    }
  }

  &__right {
    display: flex;
    height: 100%;
    margin-right: 18px;

    @include mq($from: mobile) {
      margin-right: 15px;
    }
  }
}
</style>
