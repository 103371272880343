<template>
  <button
    class="button"
    :class="outline ? 'button--outline' : 'button--solid'"
    @click="$emit('clicked', $event)"
    :type="type"
    :disabled="disabled"
  >
    <div class="button--elf"></div>
    <slot></slot>
    <span>{{ label }}</span>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clicked'],
}
</script>

<style lang="scss">
.button {
  @include flex-center;
  cursor: pointer;
  border-radius: 2px;
  font-size: 15px;
  font-weight: $font-weight-medium;
  height: auto;
  position: relative;
  padding: 10px 16px;

  @include mq($from: mobile) {
    padding: 14px 21px;
  }

  span,
  svg {
    flex-shrink: 0;
    position: inherit;
    z-index: 1;
  }

  .elf & {
    border-radius: unset;
    font-size: 14px;
    font-weight: $font-weight-regular;
    letter-spacing: 0.04em;
    padding: 16px 32px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;

    @include mq($from: mobile) {
      padding: 16px 32px;
    }

    span {
      font-family: var(--font-family);
      white-space: nowrap;
    }

    .button--elf {
      opacity: 1;
    }
  }

  &--solid {
    background-color: $color-primary;
    border: none;

    &:hover {
      background: $color-primary-hover;
    }

    img,
    svg {
      fill: white;
      margin-right: 12px;
    }

    span {
      color: white;
      line-height: 1;
    }

    &:disabled {
      background: $color-black-50;
      color: white;
      pointer-events: none;
    }

    .elf & {
      background: var(--color-primary-hover);

      &:hover {
        .button--elf {
          opacity: 0;
        }
      }

      &:disabled {
        background: var(--color-gray-disabled);

        .button--elf {
          opacity: 0;
        }
      }
    }

    .button--elf {
      background-color: $color-primary;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  &--outline {
    background-color: transparent;
    border: 1px solid $color-grey-500;

    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;

      svg {
        fill: white;
      }

      span {
        color: white;
      }
    }

    img,
    svg {
      fill: $color-grey-600;
      margin-right: 12px;
    }

    span {
      color: $color-black-primary;
      line-height: 1;
    }

    .elf & {
      border: 1px solid $color-primary;
    }
  }
}

.button + .button {
  margin: 0;
}
</style>
