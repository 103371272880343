<template>
  <router-view @retrigger-search="retriggerSearchHandler" />
</template>

<script>
import { mapGetters } from 'vuex'
import { initSearchClient, searchAllIndexes } from '@/helpers/search'

export default {
  async mounted() {
    document.getElementById('app').classList.add(process.env.VUE_APP_BRAND_SLUG)
    this.$store.dispatch('getLinks')

    if (process.env.VUE_APP_HAS_TRANSLATIONS === 'true') {
      if (navigator.language.includes('de')) {
        this.$store.commit('setCurrentLanguage', 'de')
        this.$i18n.locale = 'de'
      } else {
        this.$store.commit('setCurrentLanguage', 'en')
        this.$i18n.locale = 'en'
      }
    } else {
      this.$store.commit('setCurrentLanguage', 'de')
      this.$i18n.locale = 'de'
    }

    if (this.user) {
      this.$store.dispatch('userValidate')

      // initialise algolia and indexes on every reload
      await this.$store.dispatch('getKeyAndIndexes')
      this.initialiseSearch()
    } else if (process.env.VUE_APP_HAS_AUTH === 'false') {
      this.$store.dispatch('getKeyAndIndexes')
    }

    window.addEventListener('resize', this.updateIsTablet)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateIsTablet)
  },
  computed: {
    ...mapGetters(['currentLanguage', 'user', 'searchClient']),
  },
  watch: {
    user(newUser, oldUser) {
      if (!oldUser && newUser) {
        this.$store.dispatch('getKeyAndIndexes')
      } else {
        this.resetStore()
      }
    },
    searchClient() {
      if (this.searchClient) this.initialiseSearch()
    },
  },
  methods: {
    initialiseSearch() {
      initSearchClient(this.searchClient)
      searchAllIndexes()
    },
    resetStore() {
      this.$store.commit('clearAllDropdowns')
    },
    retriggerSearchHandler() {
      searchAllIndexes()
    },
    updateIsTablet() {
      this.$store.commit('setIsTablet', window.innerWidth <= 768)
    }
  }
}
</script>

