<template>
  <div class="main">
    <Navigation isMain />
    <router-view />
  </div>
</template>

<script>
import { getFacetValues } from '@/helpers/search'
import Navigation from '@/components/Navigation.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Navigation,
  },
  computed: {
    ...mapGetters(['indexes']),
  },
  watch: {
    indexes: {
      handler() {
        if(this.indexes?.length) {
          getFacetValues()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.main {
  padding-top: $navbar-height;
}
</style>
