<template>
  <div class="results">
    <div
      class="results__filter-row"
      v-if="currentCategory !== 'alle' && currentFilters.length"
    >
      <div class="results__filter-row__title">
        <img src="@/assets/images/filter.svg" />
        <p>{{ $t('filter') }}</p>
      </div>
      <div class="results__filter-row__filters">
        <Filter
          v-for="filter in currentFilters"
          ref="filterRef"
          :key="filter.key"
          :filter="filter"
          @setFilter="setFilter"
        />
      </div>
      <div
        class="results__filter-row__clear"
        v-if="selectedFilters.length"
        @click="resetFilters"
      >
        <img src="@/assets/images/cross-small.svg" />
        <p>{{ isTablet ? $t('reset') : $t('reset-filters') }}</p>
      </div>
    </div>
    <p class="results__count">
      {{ currentCategoryCount }}
      {{ $tc('result', currentCategoryCount) }}
    </p>
    <div class="results__cards">
      <Card
        v-for="result in currentResults"
        :key="result.objectID"
        :data="result"
        :isSelected="selectedItemIds.includes(result.objectID)"
        @selected="addSelectedItemId"
        @deselected="removeSelectedItemId"
      />
    </div>
    <div class="results__load" v-if="showLoadMore">
      <Button outline :label="$t('button.load-more')" @click="loadMore" />
    </div>
    <SelectionModal
      :totalSelected="selectedItemIds.length"
      @download="downloadSelection"
      @addToCart="addSelectionToCart"
      @deselectAll="deselectSelection"
    />
    <el-dialog
      v-model="showAddCartDialog"
      :show-close="false"
      custom-class="add-cart"
    >
      <AddCartDialog @closeDialog="closeAddCartDialog" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ElNotification } from 'element-plus'
import { searchAllIndexes, searchIndex } from '@/helpers/search'
import Filter from '@/components/Filter.vue'
import Button from '@/components/Button.vue'
import Card from '@/components/Card.vue'
import SelectionModal from '@/components/SelectionModal.vue'
import AddCartDialog from '@/components/AddCartDialog.vue'

export default {
  components: {
    Filter,
    Button,
    Card,
    SelectionModal,
    AddCartDialog,
  },
  props: {
    general: {
      type: Boolean,
      default: false,
    },
    hasResultsInSingleCategory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFilters: [],
      selectedItemIds: [],
      selectedItemSizes: 0,
      showAddCartDialog: false,
      currentHitsPerPage: 20,
    }
  },
  mounted() {
    this.updateCategoryResults()
  },
  computed: {
    ...mapGetters([
      'isTablet',
      'user',
      'indexes',
      'currentCategory',
      'searchText',
      'categoryResults',
      'categoryResultsCount',
      'generalResults',
      'generalResultsCount',
      'newlyAddedItems',
      'cartCount',
    ]),
    currentFilters() {
      const indexIndex = this.indexes?.findIndex(
        (index) => index.key === this.currentCategory,
      )
      return this.indexes?.[indexIndex]?.filters ?? []
    },
    currentResults() {
      if (this.currentCategory === 'alle') {
        return this.generalResults?.reduce(
          (previousValue, currentValue) =>
            previousValue.concat(currentValue.hits),
          [],
        )
      } else {
        return this.categoryResults
      }
    },
    currentCategoryTotal() {
      return this.generalResults?.find(
        (result) => result.index === this.currentCategory,
      )?.nbHits
    },
    currentCategoryCount() {
      if (this.currentCategory === 'alle') {
        return this.generalResultsCount
      } else {
        return this.categoryResultsCount
      }
    },
    showLoadMore() {
      return (
        this.currentResults?.length &&
        this.currentResults?.length < this.currentCategoryCount
      )
    },
  },
  watch: {
    searchText() {
      this.resetFilters()
    },
    newlyAddedItems() {
      if (this.newlyAddedItems.length) this.showAddCartDialog = true
    },
    currentCategory() {
      this.resetFilters()
      this.resetCurrentHitsPerPage()
      this.updateCategoryResults()
    },
    selectedFilters: {
      handler() {
        if (this.currentCategory !== 'alle')
          searchIndex(
            this.currentCategory,
            this.currentHitsPerPage,
            this.selectedFilters,
          )
      },
      deep: true,
    },
  },
  methods: {
    setFilter(filter) {
      const index = this.selectedFilters.findIndex(
        (element) => element.name === filter.name,
      )
      if (index === -1) {
        // filter does not exist
        this.selectedFilters.push(filter)
      } else {
        if (filter.values.length) {
          this.selectedFilters[index] = filter
        } else {
          this.selectedFilters.splice(index, 1)
        }
      }
    },
    resetFilters() {
      this.$refs.filterRef?.forEach((ref) => {
        ref.resetFilter()
      })
      this.selectedFilters = []
    },
    addSelectedItemId(item) {
      this.selectedItemIds.push(item.objectID)
      this.selectedItemSizes += item.itemSize
    },
    removeSelectedItemId(item) {
      this.selectedItemIds = this.selectedItemIds.filter(
        (id) => id !== item.objectID,
      )
      this.selectedItemSizes -= item.itemSize
    },
    downloadSelection() {
      // -1 means no limit
      const aboveDownloadLimit = process.env.$VUE_APP_HAS_AUTH
        ? this.user.download_limit > -1 &&
          this.selectedItemSizes > this.user.download_limit
        : false

      if (aboveDownloadLimit) {
        ElNotification({
          title: this.$t('notification.download-limit', {
            mb: (this.user.download_limit / 1048576).toFixed(0),
          }),
          position: 'bottom-right',
          duration: 2000,
          type: 'error',
          showClose: false,
        })

        return
      }

      const selectedItems = this.currentResults.filter((item) =>
        this.selectedItemIds.includes(item.objectID),
      )

      const variantIds = selectedItems.reduce((previousValue, currentValue) => {
        const variantIds = currentValue.variants.map((variant) => variant.id)
        return previousValue.concat(variantIds)
      }, [])

      this.$store.dispatch('downloadAssets', { variantIds })
    },
    addSelectionToCart() {
      const selectedItems = this.currentResults.filter((item) =>
        this.selectedItemIds.includes(item.objectID),
      )

      const selectedItemsCount = selectedItems.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.variants.length
      }, 0)

      if (this.cartCount + selectedItemsCount > 100) {
        ElNotification({
          title: this.$t('notification.cart-limit', {
            number: 100,
          }),
          position: 'bottom-right',
          duration: 2000000,
          type: 'error',
          showClose: false,
        })

        return
      }

      this.$store.commit('setNewlyAddedItems', selectedItems)
      selectedItems.forEach((item) => this.$store.commit('addItemToCart', item))

      this.selectedItemIds = []
    },
    deselectSelection() {
      this.selectedItemIds = []
    },
    updateCategoryResults() {
      if (this.currentCategory === 'alle') {
        this.$store.commit('clearCategoryResults')
      } else {
        searchIndex(
          this.currentCategory,
          this.currentHitsPerPage,
          this.selectedFilters,
        )
      }
    },
    resetCurrentHitsPerPage() {
      this.currentHitsPerPage = 20
    },
    loadMore() {
      this.currentHitsPerPage += 20

      if (this.currentCategory === 'alle') {
        searchAllIndexes(this.currentHitsPerPage)
      } else {
        searchIndex(
          this.currentCategory,
          this.currentHitsPerPage,
          this.selectedFilters,
        )
      }
    },
    closeAddCartDialog() {
      this.showAddCartDialog = false
    },
  },
}
</script>

<style lang="scss">
.results {
  background-color: $color-grey-200;
  flex: 1;
  padding: 20px 16px 40px;

  @include mq($from: mobile, $until: tablet) {
    padding: 40px 30px 60px;
  }

  @include mq($from: tablet) {
    padding: 40px 65px 100px;
  }

  &__filter-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 32px;

    @include mq($from: tablet) {
      flex-wrap: nowrap;
    }

    &__title {
      display: flex;
      gap: 8px;
      font-size: 12px;
      margin-right: 24px;

      @include mq($from: tablet) {
        gap: 12px;
        font-size: 16px;
        margin: 15px 0 15px 0;
      }

      img {
        @include mq($until: mobile) {
          height: 12px;
          width: 12px;
        }
      }
    }

    &__filters {
      display: flex;
      gap: 8px;
      order: 3;
      width: 100%;
      overflow-x: auto;

      @include mq($from: tablet) {
        flex-wrap: wrap;
        overflow-x: unset;
      }
    }

    &__clear {
      display: flex;
      gap: 8px;
      order: 2;
      color: $color-grey-600;
      cursor: pointer;
      font-size: 12px;
      margin-left: auto;
      white-space: nowrap;

      @include mq($from: tablet) {
        gap: 12px;
        font-size: 16px;
        margin: 15px 0 15px 0;
        order: 3;
      }
    }
  }

  &__count {
    display: inline-flex;
    font-size: 12px;
    margin-bottom: 20px;

    @include mq($from: mobile) {
      font-size: 15px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    justify-items: center;

    @include mq($from: 350px, $until: tablet) {
      grid-template-columns: 1fr 1fr;
    }

    @include mq(tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__load {
    @include flex-center-justify;
    margin-top: 60px;
  }

  .el-dialog.add-cart {
    position: absolute;
    top: 0;

    @include mq($from: mobile) {
      top: 110px;
      right: 10px;
    }
  }
}
</style>
