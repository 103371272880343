<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.0128937 20.7177C0.714328 16.3921 5.7136 13.4279 11.1245 13.9341C15.6415 14.3558 19.2663 17.1082 19.8521 20.7175L17.8779 21.0379C17.4532 18.4212 14.619 16.269 10.9384 15.9254C6.48497 15.5088 2.5006 17.8712 1.98711 21.0379L0.0128937 20.7177ZM9.9324 0L10.1574 0.00413864C13.3671 0.122433 15.9324 2.76171 15.9324 6C15.9324 9.3136 13.2464 12 9.9324 12C6.61843 12 3.9324 9.3136 3.9324 6C3.9324 2.6864 6.61843 0 9.9324 0ZM9.9324 2L9.73274 2.0049C7.61618 2.10892 5.9324 3.85785 5.9324 6C5.9324 8.20909 7.72306 10 9.9324 10C12.1417 10 13.9324 8.20909 13.9324 6C13.9324 3.79091 12.1417 2 9.9324 2Z"
    />
  </svg>
</template>
