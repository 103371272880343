import { getKeyAndIndexes } from '@/api'

export default {
  state: () => ({
    searchClient: null,
    indexes: null,
    defaultThumbnails: null,
    currentCategory: 'alle',
    generalSearchText: '',
    categorySearchText: '',
    generalResults: [],
    categoryResults: null,
  }),
  actions: {
    async getKeyAndIndexes({ commit }) {
      try {
        const response = await getKeyAndIndexes()
        commit('setSearchClient', response.data.search_client)
        commit('setIndexes', response.data.indexes)
        commit('setDefaultThumbnails', response.data.config)
      } catch (error) {
        console.error(error)
      }
    },
  },
  mutations: {
    setSearchClient(state, payload) {
      state.searchClient = payload
    },
    setIndexes(state, payload) {
      state.indexes = payload
    },
    setDefaultThumbnails(state, payload) {
      state.defaultThumbnails = payload
    },
    setFilters(state, payload) {
      const indexIndex = state.indexes.findIndex(
        (index) => index.key === payload.index,
      )
      const filterIndex = state.indexes[indexIndex].filters.findIndex(
        (filter) => filter.key === payload.filter,
      )

      state.indexes[indexIndex].filters[filterIndex].facetHits =
        payload.facetHits
    },
    setCurrentCategory(state, payload) {
      state.currentCategory = payload
    },
    setGeneralSearchText(state, payload) {
      state.generalSearchText = payload
    },
    setCategorySearchText(state, payload) {
      state.categorySearchText = payload
    },
    setGeneralResults(state, payload) {
      state.generalResults = payload
    },
    setCategoryResults(state, payload) {
      state.categoryResults = payload
    },
    clearGeneralResults(state) {
      state.generalResults = null
    },
    clearCategoryResults(state) {
      state.categoryResults = null
    },
  },
  getters: {
    searchClient(state) {
      return state.searchClient
    },
    indexes(state) {
      return state.indexes
    },
    defaultThumbnails(state) {
      return state.defaultThumbnails
    },
    filters(state) {
      return state.filters
    },
    currentCategory(state) {
      return state.currentCategory
    },
    generalSearchText(state) {
      return state.generalSearchText
    },
    categorySearchText(state) {
      return state.categorySearchText
    },
    searchText(state) {
      return state.generalSearchText || state.categorySearchText
    },
    generalResults(state) {
      return state.generalResults
    },
    categoryResults(state) {
      return state.categoryResults?.hits
    },
    categoryResultsCount(state) {
      return state.categoryResults?.nbHits
    },
    generalResultsCount(state) {
      return state.generalResults?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.nbHits,
        0,
      )
    },
  },
}
