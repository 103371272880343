<template>
  <div class="landing">
    <div 
      class="background" 
      :class="'animated'" 
      :style="{ backgroundImage: backgroundImage 
        ? 'url(' + backgroundImage + ')' 
        : 'url(' + require('@/assets/images/landing.jpg') + ')' }" 
    />
    <component :is="user && indexes || !$VUE_APP_HAS_AUTH ? 'LandingSearch' : 'LandingAuth'" @retrigger-search="retriggerSearchHandler"></component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LandingAuth from '@/components/LandingAuth.vue'
import LandingSearch from '@/components/LandingSearch.vue'

export default {
  components: {
    LandingAuth,
    LandingSearch,
  },
  computed: {
    ...mapGetters(['user', 'indexes', 'backgroundImage']),
  },
  methods: {
    retriggerSearchHandler(){
      this.$emit('retrigger-search')
    }
  }
}
</script>

<style lang="scss">
.landing {
  @include flex-center;
  min-height: 100vh;
  width: 100vw;
  overflow-y: auto;

  .background {
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: $zIndex-landing-background;

    &.animated {
      animation: zoomIn 0.7s ease;
    }
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
