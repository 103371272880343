import {
  getUserRoles,
  userSetPassword,
  userResetPassword,
  userRegister,
  userLogin,
  userValidate,
  userUpdate,
  userLogout,
} from '@/api'
import router from '@/router'

export default {
  state: () => ({
    userRoles: null,
    userSetPassword: false,
    userSetPasswordError: false,
    userResetPassword: false,
    userResetPasswordError: false,
    userRegistered: false,
    userRegisterError: '',
    user: null,
    userLoginError: '',
    userUpdated: false,
    currentLanguage: '',
  }),
  actions: {
    async getUserRoles({ commit }) {
      try {
        const response = await getUserRoles()
        commit('setUserRoles', response.data)
      } catch (error) {
        console.error(error)
      }
    },
    async userSetPassword({ commit }, payload) {
      try {
        await userSetPassword(payload)
        commit('setUserSetPassword', true)
      } catch (error) {
        commit('setUserSetPasswordError', error.response.data.message)
        console.error(error)
      }
    },
    async userResetPassword({ commit }, payload) {
      try {
        await userResetPassword(payload)
        commit('setUserResetPassword', true)
      } catch (error) {
        commit('setUserResetPasswordError', error.response.data.message)
        console.error(error)
      }
    },
    async userRegister({ commit }, payload) {
      try {
        await userRegister(payload)
        commit('setUserRegistered', true)
      } catch (error) {
        commit('setUserRegisterError', error.response.data.message)
        console.error(error)
      }
    },
    async userLogin({ commit }, payload) {
      try {
        const response = await userLogin(payload)
        commit('setUser', response.data)
      } catch (error) {
        commit('setUserLoginError', error.response.data?.message)
        console.error(error)
      }
    },
    async userValidate({ commit }) {
      try {
        await userValidate()
      } catch (error) {
        console.error(error)
        commit('setUser', null)
        router.push({ path: '/' })
      }
    },
    async userUpdate({ commit, state }, payload) {
      try {
        const response = await userUpdate(payload)
        const newUserDetails = response.data.data

        commit('setUserUpdated', true)
        commit('setUser', {
          ...state.user,
          user_email: newUserDetails.email,
          first_name: newUserDetails.firstName,
          last_name: newUserDetails.lastName,
        })
      } catch (error) {
        console.error(error)
      }
    },
    async userLogout({ commit }) {
      try {
        await userLogout()
        commit('setUser', null)
        router.push({ path: '/' })
      } catch (error) {
        console.error(error)
      }
    },
  },
  mutations: {
    setUserRoles(state, payload) {
      state.userRoles = payload
    },
    setUserSetPassword(state, payload) {
      state.userSetPassword = payload
    },
    setUserSetPasswordError(state, payload) {
      state.userSetPasswordError = payload
    },
    setUserResetPassword(state, payload) {
      state.userResetPassword = payload
    },
    setUserResetPasswordError(state, payload) {
      state.userResetPasswordError = payload
    },
    setUserRegistered(state, payload) {
      state.userRegistered = payload
    },
    setUserRegisterError(state, payload) {
      state.userRegisterError = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    setUserLoginError(state, payload) {
      state.userLoginError = payload
    },
    setUserUpdated(state, payload) {
      state.userUpdated = payload
    },
    setCurrentLanguage(state, payload) {
      state.currentLanguage = payload
    },
  },
  getters: {
    userRoles(state) {
      return state.userRoles
    },
    userSetPassword(state) {
      return state.userSetPassword
    },
    userSetPasswordError(state) {
      return state.userSetPasswordError
    },
    userResetPassword(state) {
      return state.userResetPassword
    },
    userResetPasswordError(state) {
      return state.userResetPasswordError
    },
    userRegistered(state) {
      return state.userRegistered
    },
    userRegisterError(state) {
      return state.userRegisterError
    },
    user(state) {
      return state.user
    },
    userLoginError(state) {
      return state.userLoginError
    },
    userUpdated(state) {
      return state.userUpdated
    },
    currentLanguage(state) {
      return state.currentLanguage
    },
  },
}
